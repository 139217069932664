<button mat-icon-button mat-dialog-close class="close-button">
  <mat-icon>close</mat-icon>
</button>

@if (!isSigningUp()) {
  <div class="onboard-container">
    <div class="rsvp-confirmation">
      <img [src]="successIcon" />
      <h2>Score!</h2>
      <p>You have successfully RSVPed for <span>{{ data.event.title }}</span></p>
    </div>
    <div class="create-account">
      <div>
        <img src="assets/beans.svg" />
        <h3>Create an account</h3>
        <p>Join Gametime Hero today to track this event and organize your own!</p>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="onSignUpButtonClick()"
      >Sign up</button>
    </div>
  </div>
} @else {
  <app-sign-up [userInfo]="data.user" (signUp)="onSignUp()"></app-sign-up>
}