import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ark-calendar-icon',
  standalone: true,
  imports: [
    DatePipe,
  ],
  templateUrl: './calendar-icon.component.html',
  styleUrl: './calendar-icon.component.scss'
})
export class ArkCalendarIconComponent {
  @Input()
  date?: Date;
}
