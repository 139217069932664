import { createAction, props } from '@ngrx/store';

import { EventInfoState } from './state';

export enum EventInfoActionTypes {
    EventItemLoad = '[EventInfo Page] EventItemLoad',
    EventItemLoadSuccess = '[EventInfo Page] EventItem Load Success',
}

export const EventInfoLoadEventItem = createAction(
    EventInfoActionTypes.EventItemLoad,
    props<{ eventId: string, teamId?: string }>(),
);

export const EventInfoOnEventItemSuccess = createAction(
    EventInfoActionTypes.EventItemLoadSuccess,
    props<{ eventInfo: EventInfoState }>(),
);
