@for (tab of tabGroup.tabs(); track tab) {
  <button 
    #tabButton
    mat-button
    [id]="tab.name()"
    [disabled]="tab.disabled()"
    [class.active]="this.tabGroup.selectedTab() === tab.name()"
    (click)="selectTab(tab.name())"
  >
    @if (tab.icon()) {
      <mat-icon>{{ tab.icon() }}</mat-icon>
    }
    <p>{{ tab.label() }}</p>
  </button>
}