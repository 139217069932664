import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, effect, ElementRef, inject, input, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewInit {
  public hasControls = input<boolean>(true);

  private destroy = inject(DestroyRef);

  private contentElementRef = viewChild<ElementRef<HTMLDivElement>>('content');
  public scrollableLeft = signal<boolean>(false);
  public scrollableRight = signal<boolean>(true);

  dragging = signal(false);
  downX = 0;

  constructor() {
    effect(() => {
      if (this.dragging()) {
        this.contentElementRef().nativeElement.style.scrollSnapType = 'none';
        this.contentElementRef().nativeElement
          .querySelectorAll<HTMLElement>('ark-link-card, a')
          .forEach((link) => {
            link.style.pointerEvents = 'none';
          });
      } else {
        this.contentElementRef().nativeElement
          .querySelectorAll<HTMLElement>('ark-link-card, a')
          .forEach((link) => {
            link.style.pointerEvents = 'auto';
          });
      }
    });
  }

  ngAfterViewInit(): void {
    const element = this.contentElementRef().nativeElement;

    // Check if the content is scrollable initially
    this.checkScrollability(element);

    // Check if the content is scrollable on scroll event
    fromEvent(element, 'scroll').pipe(
      takeUntilDestroyed(this.destroy),
    ).subscribe(() => {
      this.checkScrollability(element);
    });

    // Check if content is scrollable as children are added
    const observer = new MutationObserver(() => {
      this.checkScrollability(element);
      element.scrollLeft = 0; // Todo(srevier): there's gotta be a better way to do this
    });

    observer.observe(element, { childList: true });
  }

  checkScrollability(element: HTMLDivElement): void {
    if (!this.hasControls()) return;

    this.scrollableLeft.set(element.scrollLeft === 0 ? false : true);
    this.scrollableRight.set(
      element.scrollLeft + element.clientWidth === element.scrollWidth ? false : true,
    );
  }

  scroll(direction: 'left' | 'right') {
    const scrollAmount = direction === 'right' ? 200 : -200;
    const element = this.contentElementRef().nativeElement;

    element.style.scrollSnapType = 'x mandatory';
    element.scrollTo({
      left: element.scrollLeft + scrollAmount,
      behavior: 'smooth',
    });
  }

  onDragStart(event: DragEvent) {
    this.dragging.set(true);
    event.preventDefault();
  }

  onMouseDown(event: MouseEvent) {
    if (event.target === this.contentElementRef().nativeElement) {
      this.dragging.set(true);
    }
    this.downX = event.clientX;
  }

  onMouseUp() {
    this.dragging.set(false);
  }

  onMouseLeave() {
    this.dragging.set(false);
  }

  onMouseMove(event: MouseEvent) {
    if (this.dragging()) {
      const element = this.contentElementRef().nativeElement;
      const clientX = event.clientX;
      const scrollAmount = clientX - this.downX;
      element.scrollLeft = element.scrollLeft - scrollAmount;
      this.downX = clientX;
    }
  }
}
