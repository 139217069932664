import { CurrencyPipe } from '@angular/common';
import { Component, computed, inject, input, OnInit, Signal, signal } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { SanitizeHtmlPipe } from '@gth-legacy/components/event-info/pipes/sanitize';
import { EventInfoViewModel } from '@gth-legacy/components/event-info/view-models/event-info-model';
import { EventJoinerModel } from '@index/models/event-joiner';
import { Store } from '@ngrx/store';
import { GthEventItemModel, GthUserModel } from '@sentinels/models';
import { selectJoinersMappedWithEventsByCreatorId } from '@sentinels/state/features/joiners/selectors';

import { CarouselComponent } from '../../../../../../../../gth/src/app/features/main/components/carousel/carousel.component';
import { EventCardComponent } from '../../../../../../../../gth/src/app/features/main/components/event-card/event-card.component';
import { APP_STATE, eventLoadListByUserId } from '../../../../../../public-api';
import { EventParticipantsComponent } from '../../event-participants/event-participants.component';
import { SocialShareComponent } from '../../social-share/social-share.component';

@Component({
  selector: 'gth-more-info-tab',
  standalone: true,
  imports: [
    SanitizeHtmlPipe,
    SocialShareComponent,
    EventParticipantsComponent,
    CarouselComponent,
    EventCardComponent,
    MatChipsModule,
    CurrencyPipe,
  ],
  templateUrl: './more-info-tab.component.html',
  styleUrl: './more-info-tab.component.scss',
})
export class MoreInfoTabComponent implements OnInit {
  private store = inject(Store<APP_STATE>);

  vm = input<EventInfoViewModel>();
  user = input<GthUserModel>();

  url = input<string>();
  appName = input<string>();
  showCost = input<boolean>(false);
  preview = input<boolean>(false);

  description = computed(() => this.vm()?.event?.description || null);

  eventsFromUser: Signal<{ event: GthEventItemModel; joiners: EventJoinerModel[] }[]>;

  ngOnInit(): void {
    this.store.dispatch(eventLoadListByUserId({ id: this.vm().event.creatorId }));
    this.eventsFromUser = this.store.selectSignal(
      selectJoinersMappedWithEventsByCreatorId(this.vm()?.event?.creatorId),
    );
  }
}
