import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { EventRsvpStatus } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models/user';
import { APP_ROUTES } from '@shared/helpers';

import { ParticipantsDisplayInfo } from '../view-models/event-info-model';

type UserModelWithRsvpStatus = GthUserModel & {
  metadata: {
    rsvpStatus: EventRsvpStatus;
    guestOfParticipant: boolean;
    slotName: string;
  };
}

@Component({
  selector: 'gth-participant-status-dialog',
  templateUrl: './participant-status-dialog.component.html',
  styleUrls: ['./participant-status-dialog.component.scss'],
  standalone: true,
  imports: [
    MatTabsModule,
    CommonModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class ParticipantStatusDialogComponent {
  @ViewChild('listTemplate') listTemplate: any;
  participants: ParticipantsDisplayInfo[] = [];
  isAdmin = false;

  get goingParticipants(): ParticipantsDisplayInfo[] {
    return this.participants.filter((participant) => {
      return participant.rsvpStatus === EventRsvpStatus.PLAYING;
    });
  }

  get spectatingParticipants(): ParticipantsDisplayInfo[] {
    return this.participants.filter((participant) => {
      return participant.rsvpStatus === EventRsvpStatus.SPECTATING;
    });
  }

  get notGoingParticipants(): ParticipantsDisplayInfo[] {
    return this.participants.filter((participant) => {
      return participant.rsvpStatus === EventRsvpStatus.NOT_PLAYING;
    });
  }

  get maybeParticipants(): ParticipantsDisplayInfo[] {
    return this.participants.filter((participant) => {
      return participant.rsvpStatus === EventRsvpStatus.MAYBE;
    });
  }

  constructor(
    public dialogRef: MatDialogRef<ParticipantStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {
    this.participants = data.participants;
    this.isAdmin = data.isAdmin;
  }

  onMessageButtonClick(userId: string) {
    this.dialogRef.close();
    this.router.navigate([APP_ROUTES.Messages], {
      queryParams: {
        userId,
      },
    });
  }
}
