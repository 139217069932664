<div 
  class="content" 
  #content
  (mouseleave)="onMouseLeave()"
  (mouseup)="onMouseUp()"
  (mousedown)="onMouseDown($event)"
  (mousemove)="onMouseMove($event)"
  (dragstart)="onDragStart($event)"
  [style.cursor]="dragging() ? 'grabbing' : 'auto'"
>
  <ng-content></ng-content>
</div>

@if (hasControls()) {
  <div class="scroll-buttons">
    <button mat-mini-fab color="primary" (click)="scroll('left')" [disabled]="!scrollableLeft()">
      <mat-icon>arrow_left</mat-icon>
    </button>
    <button mat-mini-fab color="primary" (click)="scroll('right')" [disabled]="!scrollableRight()">
      <mat-icon>arrow_right</mat-icon>
    </button>
  </div>
}
