import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal, viewChild } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';

@Component({
  selector: 'gth-guest-form',
  standalone: true,
  imports: [
    MatCardModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    JsonPipe,
  ],
  templateUrl: './guest-form.component.html',
  styleUrl: './guest-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestFormComponent implements OnInit {
  guestEmail = signal<string>(undefined);
  guestName = signal<string>(undefined);

  emailInput = viewChild('email', { read: NgModel });

  emailInvalid = computed<boolean>(() => this.guestEmail() && this.emailInput().invalid);

  private safeStorage = inject(SrvSafeStorageService);

  formInvalid = computed<boolean>(() =>
    !this.guestName() || !this.guestEmail() || this.emailInvalid(),
  );

  ngOnInit(): void {
    const storedUnregInfo = JSON.parse(this.safeStorage.getItem('UNREGISTERED_CONTACT_INFO'));
    if (!storedUnregInfo) return;

    this.guestEmail.set(storedUnregInfo.email);
    this.guestName.set(storedUnregInfo.fullName);
    this.emailInput().control.setValue(storedUnregInfo.email);
  }
}
