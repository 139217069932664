import { CommonModule, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, input, OnChanges } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { GthUserModel } from '@sentinels/models';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';

import { ArkUserAvatarComponent } from '../../../../../../../ark/src/public-api';
import { ParticipantStatusDialogComponent } from '../../dialogs/participant-status-dialog.component';
import { EventInfoViewModel } from '../../view-models/event-info-model';

@Component({
  selector: 'gth-event-participants',
  templateUrl: './event-participants.component.html',
  styleUrls: ['./event-participants.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatBadge,
    MatCardModule,
    CommonModule,
    ArkUserAvatarComponent,
    MatRippleModule,
    JsonPipe,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventParticipantsComponent {
  vm = input<EventInfoViewModel>();
  @Input() user: GthUserModel;

  participants = computed(() =>
    this.vm()?.uniqueEventUsersWithGuestCount || [],
  );

  participantsGuestStatus = computed(() =>
    this.vm()?.allParticipantsDisplayInfo || [],
  );

  nonGuestParticipants = computed(() =>
    this.participants()
      .filter((_participant, index) => !this.participantsGuestStatus()[index]?.isGuest)
      .sort((a, b) => {
        const aIsGthUserWithPhoto = a instanceof GthUserModel && a.model.photoURL;
        const bIsGthUserWithPhoto = b instanceof GthUserModel && b.model.photoURL;

        if (aIsGthUserWithPhoto && a.metadata?.guestCount > 0) return -1;
        if (bIsGthUserWithPhoto && b.metadata?.guestCount > 0) return 1;
        if (aIsGthUserWithPhoto) return -1;
        if (bIsGthUserWithPhoto) return 1;
        if (a instanceof GthUnregisteredUserModel) return 1;

        return 0;
      }),
  );

  visibleParticipants = computed(() => this.nonGuestParticipants().slice(0, 3));

  participantsExist = computed(() => this.participants().length > 0);

  totalCount = computed(() => this.vm()?.totalCount || 0);

  additionalParticipantsExist = computed(() => this.vm()?.totalCount > 3);
  additionalParticipantsCount = computed(() => this.vm()?.totalCount - 3);
  remainingParticipantsCount = computed(() => this.vm()?.totalParticipantsNeeded || 0);

  constructor(private dialog: MatDialog) {}

  openParticipantStatusDialog(vm:EventInfoViewModel) {
    const dialogRef = this.dialog.open(ParticipantStatusDialogComponent, {
      data: {
        participants: vm.allParticipantsDisplayInfo,
        isAdmin: vm.event?.creator?.uid === this.user?.uid,
      },
      height: '500px',
      width: '750px',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Do nothing.
    });
  }

  getPhotoUrl(user: GthUserModel | GthUnregisteredUserModel) {
    if (user instanceof GthUnregisteredUserModel) {
      return null;
    }
    return user?.model?.photoURL;
  }
}
