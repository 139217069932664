import { Component, ElementRef, inject, QueryList, viewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { TabGroupComponent } from '../../tab-group.component';

@Component({
  selector: 'gth-tab-header',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './tab-header.component.html',
  styleUrl: './tab-header.component.scss',
})
export class TabHeaderComponent {
  tabGroup = inject(TabGroupComponent);

  tabRefs = viewChildren('tabButton', { read: ElementRef });
  elementRef: ElementRef<HTMLElement> = inject(ElementRef);

  selectTab(tabName: string) {
    this.tabGroup.selectedTab.set(undefined);
    this.tabGroup.selectedTab.set(tabName);
    this.scrollTabIntoView(tabName);
  }

  scrollTabIntoView(tabName: string) {
    const selectedTabRef = this.tabRefs().find(
      (tabRef) => tabRef.nativeElement?.id === tabName,
    );

    if (!selectedTabRef) return;

    const tabIndex = this.tabGroup.tabs().findIndex(
      (tab) => tab.name() === tabName,
    );
    const tabsAmount = this.tabGroup.tabs().length;

    const isLeft = tabIndex < tabsAmount / 2;
    const containerWidth = this.elementRef.nativeElement.offsetWidth;

    this.elementRef.nativeElement.scrollTo({
      left: isLeft ? 0 : containerWidth,
      behavior: 'smooth',
    });
  }
}
