<mat-card>
  @if (hasGroups()) {
    <mat-form-field appearance="outline">
      <mat-label>Select group</mat-label>
      <mat-select [formControl]="groupNameCtrl"
                  (selectionChange)="onGroupSelect($event)">
        @for (group of groups(); track group) {
          <mat-option [value]="group">{{group}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  @if (showSlotSelection()) {
    <mat-form-field class="slot-dropdown" appearance="outline">
      <mat-label>Select slot</mat-label>
      <mat-select
        [formControl]="eventSlotCtrl"
        (selectionChange)="onSelectEventSlot($event)"
      >
        @for (eventSlot of eventSlotsWithCost(); track eventSlot.number) {
          <mat-option [value]="eventSlot">
            {{ eventSlot.name }}
            @if (eventSlot.cost) {
              - {{ eventSlot.cost | currency }}
            }
          </mat-option>
        }
      </mat-select>

      @if (eventSlotCtrl.errors?.availableSlots) {
        <mat-error>There are no more spots available for this slot.</mat-error>
      }
      @if (eventSlotCtrl.errors?.required) {
        <mat-error>Slot selection is required.</mat-error>
      }
      @if (displayWaitlistWarning) {
        <mat-hint>This attendee will be waitlisted</mat-hint>
      } @else if (selectedEventSlot()) {
        <mat-hint>
          Remaining spots
          {{ groupName ? 'for ' + groupName + ' ' : ' ' }}
          after selection: {{ remainingAvailableSlots() }}
        </mat-hint>
      }
    </mat-form-field>
  }

  @if (showGuestFields()) {
    <mat-form-field appearance="outline">
      <mat-label>Guest Name</mat-label>
      <input
        matInput
        type="text"
        name="guestName"
        [formControl]="guestNameCtrl"
      />
      <mat-error>Guest name is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Guest Email</mat-label>
      <input
        matInput
        type="email"
        name="guestEmail"
        [formControl]="guestEmailCtrl"
      />
      <mat-error>Please enter a valid guest email.</mat-error>
    </mat-form-field>
  }

  <div class="action-row">
    <mat-button-toggle-group
      class="small-toggle"
      [formControl]="slotParticipantCtrl"
    >
      @for (option of buttonMenuOptions; track option.value) {
        <mat-button-toggle
          [value]="option.value"
          (click)="onRsvpChange(option.value)"
        >
          {{ option.viewValue }}
        </mat-button-toggle>
      }
    </mat-button-toggle-group>

    <button
      mat-icon-button
      color="warn"
      (click)="onDeleteBtnClick()"
    >
      <mat-icon fontIcon="delete" />
    </button>
  </div>
</mat-card>