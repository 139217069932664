import { Component, Input, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { GthEnvironment } from '@gth-legacy/tokens/environment-tokens';
import { Location } from '@index/interfaces';
import { GthEventItemModel } from '@sentinels/models';

@Component({
  selector: 'gth-cal-link',
  templateUrl: './cal-link.component.html',
  styleUrls: ['./cal-link.component.scss'],
  imports: [
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
  ],
  standalone: true,
})
export class CalLinkComponent {
  @Input() config: GthEnvironment;

  title = input.required<string>();
  location = input.required<Location>();
  description = input.required<string>();
  dateStart = input.required<Date>();
  dateEnd = input.required<Date>();
  eventId = input.required<string>();

  constructor() {}

  openGoogleLink() {
    const eventTitle = encodeURIComponent(this.title());
    const eventLocation = encodeURIComponent(this.location().formattedAddress);
    const eventDetails = encodeURIComponent(this.description());

    // Format start and end dates in UTC format
    const startDate = new Date(this.dateStart()).toISOString().replace(/-|:|\.\d+/g, '');
    const endDate = new Date(this.dateEnd()).toISOString().replace(/-|:|\.\d+/g, '');

    // eslint-disable-next-line max-len
    const googleCalendarLink = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${eventTitle}&dates=${startDate}/${endDate}&location=${eventLocation}&details=${eventDetails}`;
    window.open(googleCalendarLink, '_blank');
  }

  generateICalFile() {
    const startDate = new Date(this.dateStart()).toISOString().replace(/-|:/g, '')
      .replace(/\.\d{3}Z/, 'Z');
    const endDate = new Date(this.dateEnd()).toISOString().replace(/-|:/g, '')
      .replace(/\.\d{3}Z/, 'Z');

    // eslint-disable-next-line max-len
    const icalString = `BEGIN:VCALENDAR\r\nVERSION:2.0\r\nCALSCALE:GREGORIAN\r\nMETHOD:PUBLISH\r\nBEGIN:VEVENT\r\nDTSTAMP:${new Date().toISOString().replace(/[-:]/g, '').replace(/\.\d{3}/, '').replace(/(?<=.{15})/, '')}\r\nDTSTART:${startDate}\r\nDTEND:${endDate}\r\nSTATUS:CONFIRMED\r\nSUMMARY:${this.title()}\r\nDESCRIPTION:${this.description().replace(/(\r\n|\n|\r)/gm, '\\n')}\r\nCLASS:PUBLIC\r\nCREATED:${new Date().toISOString().replace(/[-:]/g, '').replace(/\.\d{3}/, '').replace(/(?<=.{15})/, '')}\r\nGEO:${this.location().lat};${this.location().lng}\r\nLOCATION:${this.location().formattedAddress.replace(/(\r\n|\n|\r)/gm, '\\n')}\r\nURL:${this.config.root}/discover/games/${this.eventId()}\r\nLAST-MODIFIED:${new Date().toISOString().replace(/[-:]/g, '').replace(/\.\d{3}/, '').replace(/(?<=.{15})/, '')}\r\nUID:${this.eventId()}@meetup.com\r\nEND:VEVENT\r\nEND:VCALENDAR`;

    const blob = new Blob([icalString], { type: 'text/calendar' });

    // Create a link element
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${this.title()}.ics`);

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  }
}
