<form [formGroup]="formGroup">
  <ng-container *ngFor="let question of eventSurvey.questions">
    <mat-card class="question-card">
      <mat-card-header>
        <mat-card-title>{{ question.text }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-container [ngSwitch]="question.answerType">
          <ng-container *ngSwitchCase="answerTypes.Short_Text">
            <mat-form-field class="answer-input">
              <input matInput type="text" [formControlName]="question.text">
              <mat-error>This Answer is invalid.</mat-error>
              
            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="answerTypes.Number">
            <mat-form-field class="answer-input">
              <input matInput type="number" [formControlName]="question.text">
              <mat-error>This Answer is invalid.</mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="answerTypes.Checkbox">
            <mat-checkbox [formControlName]="question.text">Check here to confirm</mat-checkbox>
          </ng-container>
          <ng-container *ngSwitchCase="answerTypes.Selection">
            <mat-form-field class="answer-input">
              <mat-label>Select an option</mat-label>
              <mat-select [formControlName]="question.text">
                <mat-option *ngFor="let option of question.selections" [value]="option">{{ option.value }}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </ng-container>
</form>
