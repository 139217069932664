import { Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EventJoiner, EventSlot, EventTicketLevel } from '@index/interfaces';

import { FormControlWithWarnings, SlotFormGroup } from '../rsvp-steps/rsvp-steps.component';
import { RsvpSlotComponent } from './components/rsvp-slot/rsvp-slot.component';

export enum SlotParticipant {
  SELF = 'For Myself',
  GUEST = 'For Guest',
}

type SlotValue = Partial<{
  groupName: string;
  eventSlot: EventSlot;
  slotParticipant: SlotParticipant;
  guestName: string;
  guestEmail: string;
}>

function eventSlotValidator(
  isBasic: boolean = true,
  eventSlots: EventSlot[] = [],
  participants: EventJoiner[] = [],
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const controlWithWarnings = control as FormControlWithWarnings<EventSlot>;

    const selectedEventSlot = control.value as EventSlot;
    if (!selectedEventSlot) return null;

    if (isBasic && eventSlots[0].capacity === 0) return null;

    const eventSlotFormGroup = control.parent as FormGroup<SlotFormGroup>;
    const parentFormArray =
      eventSlotFormGroup?.parent as FormArray<FormGroup<SlotFormGroup>>;

    const otherSlotsWithSameNumber = parentFormArray?.value.filter((s) => {
      return s?.eventSlot?.number === selectedEventSlot?.number;
    });
    const otherParticipantsWithSameSlot = participants?.filter((p) => {
      return p.slotNumber === selectedEventSlot.number;
    });
    const remainingAvailableSlots =
      (selectedEventSlot?.capacity ?? 0) -
      (otherSlotsWithSameNumber?.length ?? 0) -
      (otherParticipantsWithSameSlot?.length ?? 0) -
      1;

    // return remainingAvailableSlots < 0 ? { availableSlots: true } : null;
    if (remainingAvailableSlots < 0) {
      controlWithWarnings.warnings = { slotsFull: true };
    } else {
      controlWithWarnings.warnings = {};
    }
  };
}

function eventGroupSlotValidator(participants: EventJoiner[] = []): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const controlWithWarnings = control as FormControlWithWarnings<EventSlot>;

    const selectedEventSlot = control.value as EventSlot;
    if (!selectedEventSlot) return null;

    const eventSlotFormGroup = control.parent as FormGroup<SlotFormGroup>;
    const parentFormArray =
      eventSlotFormGroup?.parent as FormArray<FormGroup<SlotFormGroup>>;

    /** Calculate Group-Specific Capacity */
    const slotCapacity = selectedEventSlot.capacity || 0;
    const groupCapacity = selectedEventSlot.capacity || 0;
    const numOfSlotGroups = selectedEventSlot?.groupsList?.length || 1;
    const capacityPerGroup = Math.floor(slotCapacity / numOfSlotGroups);
    const totalCapacity = groupCapacity * numOfSlotGroups; // Account for groups

    if (!parentFormArray) return null;
    /** Count Selections for Each Group */
    const groupCounts: {[k: string]: number} = {};
    for (const s of parentFormArray.value) {
      if (s?.eventSlot?.number === selectedEventSlot.number) {
        const groupName = s.groupName; // Assuming one group per slot
        groupCounts[groupName] = (groupCounts[groupName] || 0) + 1;
      }
    }

    for (const s of participants) {
      if (s.slotNumber === selectedEventSlot.number) {
        const groupName = s.groupName; // Assuming one group per slot
        groupCounts[groupName] = (groupCounts[groupName] || 0) + 1;
      }
    }

    const groupsExceedingCapacity = [];
    /** Check if ANY Group Exceeds Capacity */
    // eslint-disable-next-line guard-for-in
    for (const _groupName in groupCounts) {
      for (const groupName in groupCounts) {
        if (groupCounts[groupName] > capacityPerGroup) {
          // return { availableSlots: true, group: groupName };
          if (groupName === 'null') continue; // Todo: Figure out why this is happening
          groupsExceedingCapacity.push(groupName);
        }
      }
    }

    if (groupsExceedingCapacity.length) {
      const groupsWithWarnings = Array.from(new Set(groupsExceedingCapacity));
      controlWithWarnings.warnings = { slotsFull: true, groups: groupsWithWarnings };
    } else {
      controlWithWarnings.warnings = {};
    }

    /** If no groups exceed capacity, check if overall capacity is exceeded */
    const totalSelections = Object.values(groupCounts)
      .reduce((sum, count) => sum + count, 0);
    const overallCapacityExceeded = totalSelections > totalCapacity;

    // return overallCapacityExceeded ? { availableSlots: true } : null;
    if (overallCapacityExceeded) {
      controlWithWarnings.warnings = { slotsFull: true };
    }
  };
}

@Component({
  selector: 'gth-rsvp-slot-management',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    RsvpSlotComponent,
  ],
  templateUrl: './rsvp-slot-management.component.html',
  styleUrl: './rsvp-slot-management.component.scss',
})
export class RsvpSlotManagementComponent implements OnInit {
  eventSlots = input<EventSlot[]>([]);
  inputEventJoiners = input<EventJoiner[]>([]);
  eventRequiresGuestInformation = input(false);
  ticketLevels = input<EventTicketLevel[]>([]);
  slotsFormArray = input(new FormArray<FormGroup<SlotFormGroup>>([]));
  slotsFormArrayValueSignal = signal<SlotValue[]>([]);
  participants = input<EventJoiner[]>([]);
  isBasic = input(false);
  hasGroups = input(false);

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.inputEventJoiners().forEach((joiner) => {
      const guestName = joiner.guestOfParticipant ? (joiner as any).guestName : null;
      const guestEmail = joiner.guestOfParticipant ? (joiner as any).guestEmail : null;
      const eventSlot = this.eventSlots().find((slot) => slot.number === joiner.slotNumber);
      const groupName = joiner.groupName ?? null;
      this.addSlotToForm({
        guestName,
        guestEmail,
        eventSlot,
        groupName,
      });
    });

    this.slotsFormArray().valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((slots) => {
      this.slotsFormArrayValueSignal.set(slots);
    });
  }

  onRemoveSlot(index: number) {
    this.slotsFormArray().removeAt(index);
  }

  addSlotToForm({
    guestName,
    guestEmail,
    eventSlot,
    groupName,
    slotParticipant,
  }: SlotValue = {}) {
    const presetEventSlot = eventSlot ?? this.eventSlots()[0];
    const presetSlotParticipant = slotParticipant ?? this.slotFormGroupContainsMyself() ?
      SlotParticipant.GUEST :
      SlotParticipant.SELF;

    const guestEmailValidators = [Validators.email];
    const guestValidator = this.eventRequiresGuestInformation() ? Validators.required : null;

    if (this.eventRequiresGuestInformation()) {
      guestEmailValidators.push(guestValidator);
    }

    const formGroup = new FormGroup<SlotFormGroup>({
      guestName: new FormControl(guestName, guestValidator),
      guestEmail: new FormControl(guestEmail, guestEmailValidators),
      eventSlot: new FormControlWithWarnings(
        presetEventSlot,
        [
          Validators.required,
          this.hasGroups() ?
          eventGroupSlotValidator(this.participants()) :
          eventSlotValidator(this.isBasic(), this.eventSlots(), this.participants()),
        ],
      ),
      slotParticipant: new FormControl(presetSlotParticipant, Validators.required),
    });

    if (this.hasGroups()) {
      formGroup.addControl('groupName', new FormControl(groupName, Validators.required));
    }

    this.slotsFormArray().push(formGroup);
  }

  private slotFormGroupContainsMyself(): boolean {
    return this.slotsFormArray().controls.some((slot) =>
      slot.value.slotParticipant === SlotParticipant.SELF,
    );
  }
}
