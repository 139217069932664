import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';

@Pipe({
  name: 'sanitizeHtml',
  standalone: true,
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private safeWindow: SrvSafeWindowService,
  ) {}

  transform(value: string): SafeHtml {
    if (this.safeWindow.hasWindow) {
      const doc = new DOMParser().parseFromString(value, 'text/html');
      if (doc.body.childNodes.length > 0) {
        return this.sanitizer.bypassSecurityTrustHtml(value);
      } else {
        return value;
      }
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
