@if (showRsvpSteps()) {
  @if (showLogin()) {
    <div class="fx-row fx-gap-10x">
      <div class="button-container">
        <a class="main-action-btns login-btn" mat-flat-button color="primary"
          (click)="onLoginClick()">Log in to join!</a>
      </div>
    </div>
  } @else {
    <gth-rsvp-steps
      [vm]="vm()"
      [isGuestProfile]="isGuestProfile()"
      [user]="user()"
      [preview]="preview()"
      [rsvpInfo]="rsvpInfo()"
      (joinEvent)="onJoinEvent($event)"
    ></gth-rsvp-steps>
  }
} @else {
  <div class="rsvped-container">
    <img [src]="rsvpedIcon" />
    <h3>Thank you for RSVPing to this event!</h3>
    <button mat-flat-button color="primary" (click)="onChangeRSVPClick()">Change Reservation</button>
  </div>
}