import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AnswerType, EventQuestion, EventSurvey } from '@index/interfaces';

@Component({
  selector: 'gth-answer-questionnaire',
  templateUrl: './answer-questionnaire.component.html',
  styleUrls: ['./answer-questionnaire.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
  ],
})
export class AnswerQuestionnaireComponent implements OnInit {
  @Input() eventSurvey: EventSurvey;
  formGroup: FormGroup;

  @Input() surveyAnswers: any| null = null;

  answerTypes = AnswerType;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
    if (this.eventSurvey && this.eventSurvey.questions) {
      this.eventSurvey.questions.forEach((question) => {
        const validators = [];
        if (question.required) {
          validators.push(Validators.required);
        }
        if (question.text) {
          validators.push(Validators.maxLength(255));
        }
        this.formGroup.addControl(question.text, new FormControl('', validators));
      });
    }

    if (this.surveyAnswers) {
      this.formGroup.patchValue(this.surveyAnswers);
    }
  }

  get answers() {
    return this.formGroup.value;
  }

  get hasErrors() {
    return !!Object.keys(this.formGroup.controls).find((text)=>{
      return !!this.formGroup.get(text).errors;
    });
  }
}
