import { inject, Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { StripeService } from '@gth-legacy';
import { PostRsvpSignUpDialogComponent, PostRsvpSignUpDialogContract } from '@gth-legacy/components/event-info/dialogs/post-rsvp-sign-up-dialog/post-rsvp-sign-up-dialog.component';
import { NotificationType } from '@index/enums';
import {
  CheckoutSession,
  EventItem,
  EventItemGuest,
  EventJoiner,
  EventJoinerStatus,
  EventRsvpStatus,
  Team,
} from '@index/interfaces';
import { NotificationModel } from '@index/models/notifications';
import { DBUtil } from '@index/utils/db-utils';
import { Store } from '@ngrx/store';
import { EventInfoDialogCloseMethod, StripeItemType } from '@sentinels/enums';
import { CreatePaymentIntent } from '@sentinels/interfaces';
import { GthEventItemModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';
import { AutoLoadService } from '@sentinels/services/core/auto-load.service';
import { CheckoutSessionsService } from '@sentinels/services/firebase/checkout-sessions.service';
import { EventItemService } from '@sentinels/services/firebase/event-items.service';
import { EventJoinerService } from '@sentinels/services/firebase/event-joiner.service';
import { GuestsService } from '@sentinels/services/firebase/guests.service';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { notificationAdd, notificationAddMany } from '@sentinels/state/features/notifications/actions';
import { unregisteredUserLoadOne } from '@sentinels/state/features/unregistered-user/actions';
import { selectUnregisteredUserByUid } from '@sentinels/state/features/unregistered-user/selectors';
import { APP_ROUTES } from '@shared/helpers';
import firebase from 'firebase/compat/app';
import { distanceBetween } from 'geofire-common';
import { combineLatest, from, lastValueFrom, Observable, of, Subscription, throwError } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';

// TODO: RED FLAG DO NOT CALL GTH FROM LEGACY
import { selectEventItem } from '../../../../gth/src/app/features/discover/game/list/state/selectors';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import {
  FIND_PLAYERS_CLOSE_ACTION,
  GthFindPlayersDialogComponent,
} from '../components/find-players-dialog/find-players-dialog.component';
import { GthRsvpWithGuestDialogComponent } from '../components/rsvp-with-guest-dialog/rsvp-with-guest-dialog.component';
import {
  EventInfoDialogCloseContract,
  EventInfoDialogComponent,
  EventInfoDialogOpenContract,
} from '../dialogs/event-info-dialog/event-info-dialog.component';
import { PaymentDialogComponent, PaymentDialogContract } from '../dialogs/payment-dialog/payment-dialog.component';
import {
  RSVPEventDialogCloseContract,
  RSVPEventDialogCloseMethod,
  RsvpEventDialogComponent,
} from '../dialogs/rsvp-event-dialog/rsvp-event-dialog.component';
import { DEFAULT_CURRENT_USER, GUEST_PROFILE_ID } from './auth.service';
import { ANALYTICS_CONSTS, GthAnalyticsService } from './logging/analytics.service';

@Injectable({ providedIn: 'root' })
export class EventsService implements OnDestroy {
  private subscriptions = new Subscription();

  private autoload = inject(AutoLoadService);

  constructor(
    private checkoutSessionsService: CheckoutSessionsService,
    private eventJoinersService: EventJoinerService,
    private eventItemsService: EventItemService,
    private safeWindow: SrvSafeWindowService,
    private analytics: GthAnalyticsService,
    private stripeService: StripeService,
    private guestsService: GuestsService,
    private firestore: AngularFirestore,
    private usersService: UserService,
    private teamService: TeamsService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private store: Store,
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async onJoinGame(
    game: GthEventItemModel,
    joiners: EventJoiner[],
    rsvpStatus: EventRsvpStatus,
    guests: EventItemGuest[] = [],
    withPrompt = true,
    joinerStatus?: EventJoinerStatus,
    isGuestUser = false,
  ): Promise<boolean> {
    const success = await this.eventJoinersService
      .joinEvent(joiners, game.id, rsvpStatus, [], joinerStatus);

    if (withPrompt) {
      const player = joiners[0].player;
      if (success) this.onJoinGameSuccess(player, game.id);
      else this.snackbar.open('Error joining game.', 'OK');
    }

    if (success && game.allowUnregistered) {
      const unregisteredUser = joiners.find((j) => j.isUnregisteredUser);
      if (unregisteredUser) {
        const userId = unregisteredUser.player;
        this.autoload.autoLoadIfNotFound(
          selectUnregisteredUserByUid(userId),
          unregisteredUserLoadOne({ uid: userId }),
        ).pipe(
          first((user) => !!user),
        ).subscribe((user) => {
          this.openPostJoinLoginDialog(game, user);
        });
      }
    }

    return success ?? false;
  }


  loadSingleGameData(
    eventId: string,
    user: GthUserModel,
    teamId?: string,
  ): Observable<{ game: GthEventItemModel; user: GthUserModel }> {
    const event$ = this.store
      .select(selectEventItem(eventId) as any)
      .pipe(
        switchMap((game) => {
          if (!game) {
            return this.getEventById$(eventId);
          } else {
            // If game exists in the store, just emit it
            return of(game);
          }
        }),
      );

    const user$ = of(user);
    let team$: Observable<Team | undefined> = of(undefined);
    if (teamId) {
      team$ = this.teamService.getTeamByTeamId$(teamId);
    }
    const requests$ = [event$, user$, team$];
    return combineLatest(requests$).pipe(
      map(([eventLatest, userLatest, teamLatest]) => {
        const team = teamLatest as GthTeamModel;
        const event = eventLatest as GthEventItemModel;
        const user = userLatest as GthUserModel;
        if (!event.creator) {
          event.setCreator(user);
        }
        const response = { game: event, user, team };
        if (team) {
          response.team = new GthTeamModel(team.id, team as any) as any;
        }
        return response;
      }),
    );
  }

  populateGameJoiners$(game: GthEventItemModel): Observable<GthUserModel[]> {
    const p = game.participants;

    return this.getParticipants$(p).pipe(
      map((users) =>
        users.filter(Boolean), // Filter out deleted users
      ),
    ) as Observable<GthUserModel[]>;
  }

  private getParticipants$(participants: EventJoiner[]) {
    if (participants.length === 0) {
      return of([] as GthUserModel[]);
    }
    const requests$: Observable<GthUserModel | undefined>[] = [];
    participants.forEach((p) => {
      let request: Observable<GthUserModel | undefined>;
      if (p.isGuestUser) request = from(this.guestsService.getGuestByEmail(p.player));
      else request = this.usersService.getUser$(p.player);
      requests$.push(request);
    });
    return combineLatest(requests$).pipe(
      map((participants) => {
        // Filter out deleted users
        return participants.filter((x) => {
          return x !== undefined;
        });
      }),
    );
  }

  async populateGameJoiners(game: GthEventItemModel) {
    const approvedPlayers = game.participants.filter(
      (p) => p.status === EventJoinerStatus.Approved,
    );

    return await this.getParticipants$(approvedPlayers).pipe(take(1)).toPromise();
  }

  async openDisplayInfoPage(
    context: string,
    game: GthEventItemModel,
    user: GthUserModel,
    team?: GthTeamModel,
  ) {
    const platform = context === 'meh' ? context : 'gth';
    if (!user && platform === 'meh') {
      return Promise.resolve(false);
    }
    this.analytics.logEvent(ANALYTICS_CONSTS.eventOpened, {
      context,
      event: game.id,
    });
    return await this.displayEventsPage(
      platform,
      game, user, team,
    ).toPromise();
  }

  getEventById$(eventItemId: string): Observable<GthEventItemModel | null> {
    return from(this.eventItemsService.list({ eventItemId })).pipe(
      switchMap((events) => {
        const event = events[0];
        let creatorId: undefined | string = '';

        if (event.creatorId || event.creator) {
          creatorId = event.creatorId || event?.creator?.id;
        }

        if (!event || !creatorId) {
          // Handle the case when events is empty.
          return of(null); // You can also return `of(null)` or any other default value.
        }

        const requests$ = this.usersService.getUserById$(creatorId);

        return combineLatest([requests$]).pipe(
          map((users) => {
            if (users && users[0]) {
              event.setCreator(users[0]);
            }

            return event;
          }),
        );
      }),
    );
  }

  getEvents$(userId: string): Observable<GthEventItemModel[]> {
    return from(this.eventItemsService.list({ userId })).pipe(
      switchMap((events) => {
        const creatorIds: string[] = [];
        events.forEach((g) => {
          if (g.creatorId && !creatorIds.includes(g.creatorId)) {
            creatorIds.push(g.creatorId);
          }
        });

        if (!events.length) {
          // Handle the case when events is empty.
          return of([]); // You can also return `of(null)` or any other default value.
        }

        const requests$: Observable<GthUserModel | undefined>[] = [];
        creatorIds.forEach((c) => {
          const request = this.usersService.getUserById$(c);
          requests$.push(request);
        });

        return combineLatest(requests$).pipe(
          map((users) => {
            // Filter out deleted users
            return users.filter((x) => x !== undefined);
          }),
          map((users) => {
            events.forEach((g) => {
              if (g.creatorId) {
                const user = users.find((u) => u && u.uid === g.creatorId);
                if (user) {
                  g.setCreator(user);
                }
              }
            });
            return events;
          }),
        );
      }),
    );
  }

  filterUpcomingEvents = (events: GthEventItemModel[]) => {
    return events?.length ?
      events
        .filter((e) => {
          const today = new Date();

          let gameDate = e?.dateEnd;
          if (!gameDate) return false;

          /** Convert to date if string */
          if (typeof gameDate === 'string') gameDate = new Date(gameDate);
          return today.getTime() < gameDate?.getTime();
        }) :
      events;
  };

  filterPastEvents = (events: GthEventItemModel[]) => {
    return events?.length ?
      events.filter((e) => {
        const today = new Date();

        let gameDate = e?.dateEnd;
        if (!gameDate) return false;

        /** Convert to date if string */
        if (typeof gameDate === 'string') gameDate = new Date(gameDate);
        return today.getTime() > gameDate?.getTime();
      }) :
      events;
  };

  openPostJoinLoginDialog(event: GthEventItemModel, user: GthUnregisteredUserModel) {
    if (!user) return;
    const dialogData: PostRsvpSignUpDialogContract = {
      event,
      user: user,
    };

    this.dialog.open(PostRsvpSignUpDialogComponent, {
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      width: 'fit-content',
      maxWidth: '800px',
      maxHeight: '900px',
      data: dialogData,
    });
  }

  openFindPlayersDialog(event: GthEventItemModel, user: GthUserModel, team?: GthTeamModel) {
    if (!user) return;

    const dialogRef = this.dialog.open(GthFindPlayersDialogComponent, {
      id: 'find-players-dialog',
      data: { event, user, team },
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
    });

    dialogRef.afterClosed().forEach(async (closeAction: FIND_PLAYERS_CLOSE_ACTION) => {
      if (!closeAction) return;

      switch (closeAction) {
        case FIND_PLAYERS_CLOSE_ACTION.REMIND_TEAM:
          const notification = new NotificationModel(
            undefined,
            NotificationType.TEAM_REMINDER,
            {
              event: event.id,
              team: team?.id ?? '',
              user: user.uid,
            },
          );
          this.sendTeamNotification(notification, team);
          break;
        case FIND_PLAYERS_CLOSE_ACTION.FIND_AVAILABLE_PLAYERS:
          this.router.navigate([APP_ROUTES.DiscoverPlayers]);
          break;
        case FIND_PLAYERS_CLOSE_ACTION.CREATE_PUBLIC_GAME:
        case FIND_PLAYERS_CLOSE_ACTION.CREATE_PRIVATE_GAME:
          const discoverableText = event.discoverable ? 'private' : 'public';
          const confimDialogRef = this.dialog.open(
            ConfirmDialogComponent,
            {
              data: {
                title: `Make ${event.title} ${discoverableText}?`,
                // eslint-disable-next-line max-len
                description: `Are you sure you would like to make this event ${discoverableText}.`,
                buttonText: `Make ${discoverableText}`,
              },
            },
          );
          confimDialogRef.afterClosed().pipe(take(1)).forEach(async (confirm: boolean) => {
            if (confirm) {
              event.discoverable = !event.discoverable;
              await this.eventItemsService.updateEvent(event.copy)
                .then((success) => {
                  if (success) {
                    this.snackbar.open(`Event is now ${discoverableText}`, '');
                    this.router.navigate([APP_ROUTES.DiscoverGames, event.id]);
                  } else {
                    this.snackbar.open(
                      `Failed to make event ${discoverableText}`,
                      'OK',
                      { duration: 0 },
                    );
                  }
                });
            }
          });
          break;
      }
    });
  }

  getAllEvents$() {
    interface EventItemWithId extends EventItem {
      id: string;
    }
    const events$ = this.firestore
      .collection(DBUtil.EventItem)
      .valueChanges({ idField: 'id' }) as unknown as Observable<EventItemWithId[]>;
    return events$.pipe(
      take(1),
      /** Convert EventItem[] to GthEventItemModel[] */
      map((events) => events.map((event) => new GthEventItemModel(event.id, event))),
      /** Load Event Joiners */
      switchMap(async (e) => this.getEventJoiners$(e)),
    );
  }

  getOnlineEvents$() {
    interface EventItemWithId extends EventItem {
      id: string;
    }
    const events$ = this.firestore
      .collection(DBUtil.EventItem, (ref) => ref.where('online', '==', true))
      .valueChanges({ idField: 'id' }) as unknown as Observable<EventItemWithId[]>;
    return events$.pipe(
      take(1),
      /** Convert EventItem[] to GthEventItemModel[] */
      map((events) => events.map((event) => new GthEventItemModel(event.id, event))),
      /** Load Event Joiners */
      switchMap(async (e) => this.getEventJoiners$(e)),
    );
  }

  getEventsByLocation$(lat: number, lng: number) {
    /** Find cities within 200km of specified location */
    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];

    if (!lat || !lng) {
      return of([]);
    }

    interface EventItemWithId extends EventItem {
      id: string;
    }
    const events$ = this.firestore
      .collection(DBUtil.EventItem)
      .valueChanges({ idField: 'id' }) as unknown as Observable<EventItemWithId[]>;
    return events$.pipe(
      take(1),
      /** Filter events that within 200km of the specified location */
      map((events) => {
        return events.filter((event) => {
          if (!event?.location?.lat || !event?.location?.lng) return false;

          const distanceInKm = distanceBetween(
            [event.location.lat, event.location.lng],
            center,
          );
          const distanceInM = distanceInKm * 1000;
          return distanceInM <= radiusInM;
        });
      }),
      /** Convert EventItem[] to GthEventItemModel[] */
      map((events) => events.map((event) => new GthEventItemModel(event.id, event))),
    );
  }

  async getEventJoiners$(events: GthEventItemModel[]) {
    for await (const event of events) {
      const joiners = (await this.eventJoinersService
        .list(event?.id));
    }
    return events;
  }

  async onCancelGame(game: GthEventItemModel, user: GthUserModel) {
    if (game.creatorId !== user.uid) return;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      id: 'confirm-cancel-event-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
      data: {
        title: `Cancel ${game.title}?`,
        description: 'Are you sure you would like to cancel this event?',
      },
    });

    dialogRef.afterClosed().forEach((confirmed) => {
      if (!confirmed) return;

      this.cancelGame(game);
    });
  }

  private async cancelGame(game: GthEventItemModel) {
    const participants = game.participants;
    game.cancel();

    if (!game.cost) game.model.cost = 0;

    await this.eventItemsService.updateEvent(game.copy)
      .then((success) => {
        if (success) {
          this.snackbar.open('Cancelled event', 'OK');

          const cancelNotification = (userId: string) =>
            new NotificationModel(undefined, NotificationType.EVENT_CANCELLED, {
              event: game.id,
              joiner: userId,
              recipient: 'joiner',
            });

          const participantIds = participants.map((p) => p.player);

          participantIds.forEach((userId) => {
            this.store.dispatch(notificationAdd({
              userId,
              notification: cancelNotification(userId),
            }));
          });
        } else {
          this.snackbar.open('Something went wrong cancelling event', 'OK');
        }
      }).catch((error) => {
        return throwError(error);
      });
  }

  private async onJoinGameSuccess(userId: string, eventItemId: string) {
    const eventJoiners = await this.eventJoinersService.list(eventItemId);

    if (!eventJoiners?.length) return;

    const player = eventJoiners.find((eventJoiner) => eventJoiner.player === userId);
    if (!player) return;

    const status = player.status;
    let message: string;
    const { rsvpStatus } = (player as any);
    let participationType = 'Participating';
    switch (status) {
      case EventJoinerStatus.Waitlisted:
        message = 'Successfully joined the waitlist';
        break;
      case EventJoinerStatus.PendingApprovers:
      case EventJoinerStatus.PendingCreator:
        message = 'Successfully requested to join the event';
        break;
      default:
        switch (rsvpStatus) {
          case EventRsvpStatus.ATTEMPTING:
          case EventRsvpStatus.MAYBE:
            participationType = 'Maybe';
            break;
          case EventRsvpStatus.NOT_PLAYING:
            participationType = 'Not Participating';
            break;
          case EventRsvpStatus.PLAYING:
            participationType = 'Participating';
            break;
          case EventRsvpStatus.WAITLISTED:
              participationType = 'Waitlister';
              break;
          case EventRsvpStatus.SPECTATING:
            participationType = 'Spectating';
            break;
        }
        message = `You've successfully marked yourself as a '${participationType}'`;
        break;
    }
    if (message) this.snackbar.open(message);
  }

  async onLeaveGame(game: GthEventItemModel, user: GthUserModel) {
    const leave = this.eventJoinersService.leaveEvent(user.uid, game.id);

    return await leave.then((success) => {
      if (!success) {
        this.snackbar.open('Error leaving game.', 'OK');
        return;
      }
      this.onLeaveGameSuccess();
    });
  }

  private onLeaveGameSuccess() {
    this.snackbar.open('Successfully left game');
  }

  private displayEventsPage(
    platform: 'gth' | 'meh',
    event: GthEventItemModel,
    user: GthUserModel,
    team?: GthTeamModel,
  ): Observable<boolean> {
    switch (platform) {
      case 'meh':
        {
          if (!team) return of(false);
          const eventInfoDialogContract: EventInfoDialogOpenContract = {
            event: event.id,
            user,
            team: team.id,
            platform,
          };
          const dialogRef = this.dialog.open(EventInfoDialogComponent, {
            id: 'game-info-dialog',
            data: eventInfoDialogContract,
            backdropClass: 'gth-overlay-backdrop',
            panelClass: 'gth-dialog',
          });
          return new Observable((observer) => {
            dialogRef.afterClosed()
              .subscribe(async (result: EventInfoDialogCloseContract) => {
                const joiner: EventJoiner = {
                  player: user.uid,
                  createdAt: Date.now(),
                  rsvpStatus: EventRsvpStatus.PLAYING,
                };

                if (result) {
                  switch (result.closeMethod) {
                    case EventInfoDialogCloseMethod.SAVE:
                      // eslint-disable-next-line max-len
                      const join = await this.onJoinEvent(platform, event, [joiner], undefined);
                      observer.next(join);
                      observer.complete();
                      break;
                    case EventInfoDialogCloseMethod.LEAVE:
                      await this.onLeaveGame(event, user);
                      observer.next(true);
                      observer.complete();
                      break;
                    case EventInfoDialogCloseMethod.CANCEL:
                      await this.onCancelGame(event, user);
                      observer.next(true);
                      observer.complete();
                      break;
                    case EventInfoDialogCloseMethod.PAYMENTS:
                      this.router.navigate(
                        [APP_ROUTES.Settings],
                        { queryParams: { tab: 'payments' } },
                      );
                      observer.next(true);
                      observer.complete();
                      break;
                    case EventInfoDialogCloseMethod.OTHER:
                    default:
                      observer.next(false);
                      observer.complete();
                      break;
                  }
                }
                observer.next(false);
                observer.complete();
              });
          });
        }
      case 'gth':
        this.router.navigate([APP_ROUTES.DiscoverGames, event.id]);
        return of(true);
    }
  }

  async onJoinEvent(
    platform: 'gth' | 'meh',
    event: GthEventItemModel,
    joiners: EventJoiner[],
    isGuestUser = false,
    rsvpSelected = EventRsvpStatus.ATTEMPTING,
  ): Promise<any> {
    const noishRsvp = [
      EventRsvpStatus.NOT_PLAYING,
      EventRsvpStatus.MAYBE,
      EventRsvpStatus.SPECTATING,
      EventRsvpStatus.WAITLISTED,
    ];

    const status = noishRsvp.includes(rsvpSelected) ?
      EventJoinerStatus.NotCommited : undefined;
    /** check for default user uid and open dialog to get name and email */
    const isGuest = joiners[0]
      .player === DEFAULT_CURRENT_USER.uid || joiners[0].player === GUEST_PROFILE_ID;
    if (isGuest) {
      const guestUser = await this.openRsvpEventDialog(event);
      if (!guestUser) return false;

      const joiner: EventJoiner = {
        player: guestUser.uid,
        createdAt: Date.now(),
        rsvpStatus: EventRsvpStatus.PLAYING,
      };

      return this.onJoinEvent(platform, event, [joiner], true);
    }

    const eventSlotHasCost = joiners.some((j) => {
      const eventSlot = event.ticketLevels
        .find((t) => t?.slotNumber === j.slotNumber);
      return !!eventSlot?.cost;
    });
    const eventCosts = (typeof event.cost === 'number' && event.cost !== 0) ||
      !!event?.selectedTicketLevel || eventSlotHasCost;
    const requiresPayment = eventCosts && rsvpSelected !== EventRsvpStatus.NOT_PLAYING;

    /**
     * Check if event allows participant guests
     * show dialog for user to enter guests if allowed
     */
    if (event.allowParticipantGuests) {
      const rsvpDialogRef = this.dialog.open(GthRsvpWithGuestDialogComponent, {
        id: 'rsvp-with-guest-dialog',
        backdropClass: 'gth-overlay-backdrop',
        panelClass: 'gth-dialog',
      });

      const guests = await rsvpDialogRef.afterClosed().pipe(
        first(),
      ).toPromise();

      if (!guests) {
        return true;
      }

      if (requiresPayment) {
        return this.onJoinEventWithCost(
          platform,
          event,
          joiners,
          guests,
          isGuestUser,
          rsvpSelected,
        );
      } else {
        // eslint-disable-next-line max-len
        return this.onJoinGame(event, joiners, rsvpSelected, guests, true, status, isGuestUser);
      }
    } else if (requiresPayment) {
      return this.onJoinEventWithCost(
        platform,
        event,
        joiners,
        [],
        isGuestUser,
        rsvpSelected,
      );
    } else {
      return this.onJoinGame(event, joiners, rsvpSelected, [], true, status, isGuestUser);
    }
  }

  async deleteEvent(
    game: GthEventItemModel,
  ) {
    return this.eventItemsService.deleteEvent({ eventId: game.id });
  }

  eventJoinersToStripeItems(
    event: GthEventItemModel,
    joiners: EventJoiner[],
    platform: 'gth' | 'meh' = 'gth',
  ) {
    if (!event.priceId) throw Error('Event must be called with a Stripe price');

    if (
      event?.cost ||
      event.selectedTicketLevel?.cost &&
      joiners.length === 1
    ) {
      return [
        {
          id: event.selectedTicketLevel ?
            event.selectedTicketLevel.priceId :
            event.priceId,
          name: event.selectedTicketLevel ?
            `${event.title}: ${event.selectedTicketLevel.name}` :
            event.title,
          quantity: joiners?.length ?? 1,
          cost: event.selectedTicketLevel ?
            event.selectedTicketLevel.cost :
            event.cost,
          type: StripeItemType.JOIN_EVENT,
          platform,
        },
      ];
    } else {
      if (
        event.ticketLevels.length &&
        event.ticketLevels.some((t) => !!t?.cost && !t?.priceId)
      ) {
        throw new Error('Event ticket levels must have a Stripe price');
      }

      return joiners.map((j) => {
        const joinerTicketLevel = event.ticketLevels
          .find((t) => t.slotNumber === j.slotNumber);

        const eventSlot = event.eventSlotGroup[0].slots
          .find((s) => s.number === j.slotNumber);

        let itemName = `${event.title}: ${eventSlot.name}`;

        if (j?.groupName) itemName = `${itemName} for ${j.groupName}`;

        return {
          id: joinerTicketLevel.priceId,
          name: itemName,
          quantity: 1,
          cost: joinerTicketLevel.cost,
          type: StripeItemType.JOIN_EVENT,
          platform,
        };
      });
    }
  }

  async createJoinEventCheckoutSession(contract: CreatePaymentIntent) {
    const sessionUrl = await this.stripeService.createCheckoutSession(contract);

    if (sessionUrl) this.safeWindow.open(sessionUrl, '_self');
  }

  async onJoinEventWithCost(
    platform: 'gth' | 'meh',
    event: GthEventItemModel,
    joiners: EventJoiner[],
    guests: EventItemGuest[] = [],
    isGuestUser = false,
    status: EventRsvpStatus = EventRsvpStatus.ATTEMPTING,
    userEmail?: string,
  ) {
    try {
      const userHasSubscription = platform === 'meh' ?
        event.creator?.userSubscription :
        event.creator?.subscription;

      const hasPlatformFee = userHasSubscription === 'Free';

      const checkoutSession: CheckoutSession = {
        userId: joiners[0].player,
        eventId: event.id,
        joiners: joiners.map((j) => ({
          ...j,
          isGuestUser: j.isGuestUser === undefined ? false : j.isGuestUser,
        })),
        created: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const checkoutSessionId = await this.checkoutSessionsService
        .createCheckoutSession(checkoutSession);

      if (!checkoutSessionId) {
        this.snackbar.open('Failed to create checkout session to join event');

        return Promise.resolve(false);
      }

      const contract: CreatePaymentIntent = {
        items: this.eventJoinersToStripeItems(event, joiners, platform),
        stripeAccount: event.creator.stripeId,
        email: userEmail,
        hasPlatformFee,
        platform,
        environment: environment.envName,
        checkoutSessionId,
      };

      await this.createJoinEventCheckoutSession(contract);

      return Promise.resolve(false);
    } catch (error: unknown) {
      const errorMessage = 'Error joining event with cost';

      this.snackbar.open(errorMessage);

      return Promise.reject(error);
    }
  }

  openJoinEventCostDialog(
    platform: 'gth' | 'meh',
    event: GthEventItemModel,
    joiners: EventJoiner[],
    guests: EventItemGuest[] = [],
    isGuestUser = false,
    status: EventRsvpStatus = EventRsvpStatus.ATTEMPTING,
    userEmail?: string,
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const userId = joiners[0].player;

      const userHasSubscription = platform === 'meh' ?
        event.creator?.userSubscription :
        event.creator?.subscription;

      const hasPlatformFee = userHasSubscription === 'Free';

      const contract: PaymentDialogContract = {
        type: StripeItemType.JOIN_EVENT,
        event,
        userId,
        email: isGuestUser ? userEmail : undefined,
        // eslint-disable-next-line max-len
        hasPlatformFee,
        platform,
        joiners,
      };

      const dialogRef = this.dialog.open(PaymentDialogComponent, {
        data: contract,
        minWidth: 360,
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .forEach(async (success) => {
          if (success) {
            const joiner: EventJoiner = {
              player: userId,
              createdAt: Date.now(),
              rsvpStatus: status,
            };

            return await this.onJoinGame(
              event, joiners, status, guests,
              true, undefined, isGuestUser,
            );
          } else return resolve(false);
        });
    });
  }

  openRsvpEventDialog(event: GthEventItemModel): Promise<GthUserModel | null> {
    return new Promise((resolve) => {
      const rsvpEventDialogContract = {
        event,
      };
      const dialogRef = this.dialog.open(
        RsvpEventDialogComponent,
        {
          id: 'rsvp-event-dialog',
          data: rsvpEventDialogContract,
        },
      );
      dialogRef.afterClosed()
        .pipe(first())
        .forEach(async (contract: RSVPEventDialogCloseContract) => {
          if (!contract) return resolve(null);

          switch (contract.closeMethod) {
            case RSVPEventDialogCloseMethod.RSVP:
              try {
                return resolve(await this.handleRsvpEvent(event, contract));
              } catch (error: unknown) {
                console.error('Error handling RSVP:', error);
                this.snackbar.open(
                  'Some went wrong while processing RSVP',
                  'OK',
                  { duration: 0 },
                );
                return resolve(null);
              }
            case RSVPEventDialogCloseMethod.CANCEL:
            default:
              return resolve(null);
          }
        });
    });
  }

  createEvent$(event: GthEventItemModel) {
    return from(this.eventItemsService.createEvent(event.copy));
  }

  copyEvent$(eventId: string) {
    return this.getEventById$(eventId).pipe(
      switchMap((event) => {
        if (!event) return of(null);

        const copiedEventItem = Object.assign(event.copy, {
          id: '',
          priceId: '',
          ticketLevels: event.ticketLevels.map((t) => {
            return Object.assign(t, { priceId: '' });
          }),
          cancelled: false,
          rated: false,
          created: firebase.firestore.Timestamp.now(),
          updated: firebase.firestore.Timestamp.now(),
          joiners: [],
        });
        const newEvent = new GthEventItemModel('', copiedEventItem);
        return this.createEvent$(newEvent);
      }),
    );
  }

  private async handleRsvpEvent(
    event: GthEventItemModel,
    { email, name }: RSVPEventDialogCloseContract,
  ): Promise<GthUserModel | null> {
    const isEventParticipant = (id: string) => {
      return event.participants.find((p) => p.player === id);
    };

    if (!email) return null;

    if (isEventParticipant((email))) {
      this.snackbar.open('Email account already an event participant', 'OK', { duration: 0 });
      return null;
    }

    const user = await lastValueFrom(
      this.usersService.getUserByEmail$(email).pipe(first()),
    );

    if (user) {
      if (isEventParticipant(user.uid)) {
        this.snackbar.open(
          'Email account already an event participant',
          'OK',
          { duration: 0 },
        );
        return null;
      }

      this.snackbar.open('Email account already exists', '', { duration: 3500 });
      return user;
    }

    /** check if guest user already exists */
    let guestUser = await this.guestsService.getGuestByEmail(email);
    if (guestUser) {
      console.debug('EventsService#handRsvpEvent: guest user found', guestUser);
      return guestUser;
    }

    guestUser = new GthUserModel(
      email,
      {
        uid: email,
        email: email,
        displayName: name,
        fullName: name,
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
      },
    );

    const success = await this.guestsService.createGuest(guestUser.copy);
    return success ? guestUser : null;
  }

  private sendTeamNotification(notification: NotificationModel, team: GthTeamModel) {
    const userIds = team.roster.map((player) => {
      return player.id;
    });

    this.store.dispatch(notificationAddMany({ userIds, notification }));
  }
}
