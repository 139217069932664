import { Component, input } from '@angular/core';

import { GthGoogleMapModule } from '../../../../../../public-api';

@Component({
  selector: 'gth-location-tab',
  standalone: true,
  imports: [
    GthGoogleMapModule,
  ],
  templateUrl: './location-tab.component.html',
  styleUrl: './location-tab.component.scss',
})
export class LocationTabComponent {
  latitude = input<number>();
  longitude = input<number>();

  isOnline = input<boolean>();
}
