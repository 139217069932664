import { inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { replaceUndefinedWithNull } from '@index/daos/user-dao';
import { User } from '@index/interfaces';
import { UserMapper } from '@index/mappers/user-mapper';
import { UserModel } from '@index/models/user';
import { DBUtil } from '@index/utils/db-utils';
import { GthUserModel } from '@sentinels/models';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import { lastValueFrom } from 'rxjs';

export interface GuestReadFilter {
  email?: string;
  id?: string;
  phone?: string;
}

@Injectable({ providedIn: 'root' })
export class GuestsService extends FirestoreService<User> {
  protected basePath = 'guests';
  private mapper = new UserMapper();
  override firestore = inject(AngularFirestore);

  async createGuest(user: User) {
    const userModel = user as UserModel;
    const collectionRef = this.collection;
    const docRef = userModel.uid === null ?
      collectionRef.doc() :
      collectionRef.doc(userModel.uid);
    userModel.ref = docRef as any;
    const mappedUser = replaceUndefinedWithNull((this.mapper.toMap(userModel)));

    return docRef.set(mappedUser)
      .then(async () => {
        return await lastValueFrom(docRef.get()) ? docRef.ref.id : undefined;
      });
  }

  getRefFromType$(path: string, filter: GuestReadFilter, type: string) {
    const snapshot = this.firestore.collection(path, (ref) => {
      switch (type) {
        case 'email':
          return ref
            .where('email', '==', filter.email)
            .limit(1);
        case 'phone':
          return ref
            .where('phoneNumber', '==', filter.phone)
            .limit(1);
        case 'id':
          return ref
            .where('uid', '==', filter.id)
            .limit(1);
        default: return ref.limit(1);
      }
    });

    return snapshot.get();
  }

  async getGuest(filter: GuestReadFilter): Promise<User> {
    let type: 'email' | 'phone' | 'id' | 'unset' = 'unset';
    if (filter.email) type = 'email';
    else if (filter.id) type = 'id';
    else if (filter.phone) type = 'phone';

    if (type === 'unset') return undefined;

    let querySnapshot = await lastValueFrom(
      this.getRefFromType$(DBUtil.Guests, filter, type),
    );

    if (!querySnapshot || !querySnapshot.docs || querySnapshot.docs.length === 0) {
      querySnapshot = await lastValueFrom(
        this.getRefFromType$(DBUtil.User, filter, type),
      );
      // eslint-disable-next-line max-len
      if (!querySnapshot || !querySnapshot.docs || querySnapshot.docs.length === 0) {
        return undefined;
      }
    }
    const firstDocument = querySnapshot.docs[0];
    const userData = firstDocument.data() as any;
    return {
      ...userData,
      id: firstDocument.id,
    };
  }

  getGuestByEmail(email: string) {
    const getGuestByEmail = this._isEmailValid(email) ?
      this.getGuest({ email }) : this.getGuest({ id: email });
    return getGuestByEmail.then((user) => new GthUserModel(user.uid, user))
      .then((user) => this._getUser(user));
  }

  private _getUser(user: GthUserModel) {
    if (!user.photoURL) {
      user.photoURL = '';
    }
    if (!user.fullName) {
      user.fullName = '';
    }
    if (!user.displayName) {
      user.displayName = '';
    }
    return user;
  }

  private _isEmailValid(email: string): boolean {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex
    return emailRegex.test(email);
  }
}
