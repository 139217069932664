import { APP_STATE } from '@gth-legacy';
import { createSelector } from '@ngrx/store';
import { GthEventItemModel } from '@sentinels/models';


export const selectAllEvents= (state: APP_STATE) => [
    ...state?.eventItemListFeature?.events || [],
];

export const selectEventItem = (id:string) => createSelector(
    selectAllEvents,
    (allEventItems: GthEventItemModel[]) => {
        return allEventItems.find((eventItem) => eventItem.id === id);
    },
  );
