import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { ParticipantAmountPipe } from '@ark/pipes/participant-amount.pipe';
import { DefaultEventIcon, EventTypeIcons } from '@index/enums';
import { Location } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models';
import { APP_ROUTES } from '@shared/helpers';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';

import { ArkCalendarIconComponent, ArkUserAvatarComponent } from '../../../../../../../ark/src/public-api';
import { GthEnvironment } from '../../../../../public-api';
import { CalLinkComponent } from '../cal-link/cal-link.component';
import { RsvpBarComponent } from '../rsvp-bar/rsvp-bar.component';
import { TabHeaderComponent } from './components/tab-group/components/tab-header/tab-header.component';

const MAPS_BASE_URL = () => 'https://www.google.com/maps/dir/?api=1&destination=';
@Component({
  selector: 'gth-event-main-info',
  standalone: true,
  imports: [
    DatePipe,
    RouterLink,
    MatIconModule,
    MatButtonModule,
    RsvpBarComponent,
    CalLinkComponent,
    MatTooltipModule,
    TabHeaderComponent,
    ParticipantAmountPipe,
    ArkUserAvatarComponent,
    ArkCalendarIconComponent,
  ],
  templateUrl: './event-main-info.component.html',
  styleUrl: './event-main-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventMainInfoComponent implements AfterViewInit {
  title = input.required<string>();
  description = input.required<string>();
  type = input.required<string>();
  address = input.required<string>();
  location = input<Location>();
  dateStart = input.required<Date>();
  dateEnd = input.required<Date>();
  online = input.required<boolean>();
  host = input.required<GthUserModel>();
  participantCount = input<number>();
  hasRSVPed = input<boolean>(false);
  cost = input.required<number>();
  config = input.required<GthEnvironment>();
  eventId = input.required<string>();

  scrollToBody = output<void>();

  eventTypeIcon = computed(() => EventTypeIcons[this.type()] ?? DefaultEventIcon);

  street = computed(() => {
    const addressParts = this.address()?.split(', ');
    return addressParts?.[0];
  });

  city = computed(() => {
    const addressParts = this.address()?.split(', ');
    return addressParts?.slice(1).join(', ');
  });

  APP_ROUTES = APP_ROUTES;

  private elementRef = inject(ElementRef);

  ngAfterViewInit(): void {
    this.createObserver(this.elementRef);
  }

  constructor() {
    gsap.registerPlugin(Flip);
  }

  createObserver(element: ElementRef) {
    const flippers = '.main-info-container, .gsap-flip, .gsap-flip > *';

    const intersectionObserver = new IntersectionObserver(([entry]) => {
        const bcr = entry.boundingClientRect;
        const state = Flip.getState(flippers, { props: 'border-radius' });
        entry.target.classList.toggle('stuck', bcr.top < 0);
        this.flipAnimation(state, { absolute: true });
      },
      { threshold: [1] },
    );

    intersectionObserver.observe(element.nativeElement);

    return () => {
      intersectionObserver.disconnect();
    };
  }

  flipAnimation(state: Flip.FlipState, options: { absolute: boolean } = { absolute: false }) {
    Flip.from(state, {
      absoluteOnLeave: options.absolute,
      duration: 0.275,
      fade: true,
      ease: 'power2.out',
      nested: true,
    });
  }

  onMapBtnClick() {
    const url = `${MAPS_BASE_URL()}${this.address()}`;
    window.open(url, '_blank');
  }
}
