<div class="header">
  <h1 mat-dialog-title>Event Participants</h1>
  <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>

<div class="tab-group-container">
  <mat-tab-group class="tab-group">
    <mat-tab label="Going">
      <ng-container matTabContent
        *ngTemplateOutlet="listTemplate; context: { viewParticipants: goingParticipants }"></ng-container>
    </mat-tab>
    <mat-tab label="Spectating" *ngIf="isAdmin">
      <ng-container matTabContent
        *ngTemplateOutlet="listTemplate; context: { viewParticipants: spectatingParticipants }"></ng-container>
    </mat-tab>
    <mat-tab label="Not Going" *ngIf="isAdmin">
      <ng-container matTabContent
        *ngTemplateOutlet="listTemplate; context: { viewParticipants: notGoingParticipants }"></ng-container>
    </mat-tab>
    <mat-tab label="Maybe" *ngIf="isAdmin">
      <ng-container matTabContent
        *ngTemplateOutlet="listTemplate; context: { viewParticipants: maybeParticipants }"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template let-viewParticipants="viewParticipants" #listTemplate>
  <mat-list class="list">
    @for (participant of viewParticipants; track participant) {
      <mat-list-item class="list-item">
        <h3 matListItemTitle>{{ participant.displayName }}</h3>
        <p matListItemLine>
          {{ participant.slot}}
          @if (participant.isUnregistered) {
            <span>(Unregistered)</span>
          }
          @if (participant.isGuest) {
            <span>(Guest)</span>
          }
        </p>
        <div matListItemMeta *ngIf="!participant.isGuest && !participant.isUnregistered">
          <button mat-icon-button class="nav-btn" (click)="onMessageButtonClick(participant.userId)"
                  matTooltip="Message Participant">
            <mat-icon>message</mat-icon>
          </button>
        </div>
      </mat-list-item>
    } @empty {
      <p class="no-one-in-group">There is no one in this group</p>
    }
  </mat-list>
</ng-template>
