import { createReducer, on } from '@ngrx/store';

import { EventInfoLoadEventItem, EventInfoOnEventItemSuccess } from './actions';
import { eventInfoInitialState, EventInfoState } from './state';
import { CurrentState } from './state';
export const eventInfoReducer = createReducer(
    eventInfoInitialState,
    on(
        EventInfoLoadEventItem,
        (state: EventInfoState, action: { eventId: string, teamId?: string }) => (
            {
                ...state,
                state: CurrentState.Loading,
                teamId: action.teamId,
            }),
    ),

    on(
        EventInfoOnEventItemSuccess,
        (state: EventInfoState, action) => {
            return {
                ...state,
                ...action.eventInfo,
            };
        },
    ),
);
