import { JsonPipe } from '@angular/common';
import { Component, computed, inject, input, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { EventInfoViewModel } from '@gth-legacy/components/event-info/view-models/event-info-model';
import { GthUserModel } from '@sentinels/models';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';

import { JoinEventOptions, RsvpStepsComponent } from '../../rsvp-steps/rsvp-steps.component';

const RSVPED_ICON = () => 'assets/icons/Balloon--Streamline-Plump.svg';

@Component({
  selector: 'gth-rsvp-tab',
  standalone: true,
  imports: [
    RsvpStepsComponent,
    MatButtonModule,
    JsonPipe,
  ],
  templateUrl: './rsvp-tab.component.html',
  styleUrl: './rsvp-tab.component.scss',
})
export class RsvpTabComponent {
  vm = input.required<EventInfoViewModel>();
  user = input.required<GthUserModel>();
  isGuestProfile = input<boolean>(true);
  hasRSVPed = input<boolean>(false);
  isBasic = input<boolean>();
  preview = input<boolean>();

  joinEvent = output<JoinEventOptions>();
  loginClick = output<void>();

  rsvpedIcon = RSVPED_ICON();

  changingRSVP = signal<boolean>(false);

  guestRsvps = computed(() => {
    const storedGuestRsvps = this.safeStorage.getItem('UNREGISTERED_RSVPS');
    const guestRsvpMap = new Map(JSON.parse(storedGuestRsvps));
    return {
      map: guestRsvpMap,
      current: guestRsvpMap.get(this.vm().id) as JoinEventOptions,
    };
  });

  showRsvpSteps = computed(() =>
    this.changingRSVP() ||
    (!this.hasRSVPed() && !this.guestRsvps().current),
  );
  showLogin = computed(() => this.isGuestProfile() && !this.vm().event.allowUnregistered);


  rsvpInfo = computed(() => {
    const unregisteredUserId = this.guestRsvps().current?.joiners.find((j) =>
      j.isUnregisteredUser,
    )?.player;
    return this.vm().getUserRsvpsAsJoiner(this.user()?.id ?? unregisteredUserId, false);
  });

  private safeStorage = inject(SrvSafeStorageService);

  onJoinEvent(options: JoinEventOptions) {
    this.joinEvent.emit(options);
    this.changingRSVP.set(false);

    if (options.joiners.some((j) => j.isUnregisteredUser)) {
      this.guestRsvps().map.set(this.vm().id, options);
      this.safeStorage.setItem(
        'UNREGISTERED_RSVPS', JSON.stringify([...this.guestRsvps().map]),
      );
    }
  }

  onLoginClick() {
    this.loginClick.emit();
  }

  onChangeRSVPClick() {
    this.changingRSVP.set(true);
  }
}
