import { Component, computed, Input, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { ShareModule } from 'ngx-sharebuttons';

@Component({
  selector: 'gth-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    ShareModule,
    MatSnackBarModule,
    FontAwesomeModule,
  ],
  standalone: true,
})
export class SocialShareComponent {
  @Input() url: string;
  appName = input<string>('Gametime Hero');
  preview = input<boolean>(false);
  shareDescription = computed(() =>
    `Look at this amazing event happening on ${this.appName()}!!`,
  );

  faFacebookF = faFacebookF;
  faEnvelope = faEnvelope;
  faInstagram = faInstagram;
  faTwitter = faTwitter;

  constructor(
    readonly library: FaIconLibrary,
    readonly snackBar: MatSnackBar,
  ) {
    library.addIcons(faEnvelope);
   }

  onCopyLink() {
    this.snackBar.open('Link copied');
  }
}
