import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { ButtonMenuComponent } from './button-menu.component';
import { ButtonMenuDirective } from './button-menu.directive';

@NgModule({
  exports: [ButtonMenuDirective, ButtonMenuComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  declarations: [ButtonMenuComponent, ButtonMenuDirective],
})
export class ButtonMenuModule { }
