import { JsonPipe } from '@angular/common';
import { Component, Inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { GthEventItemModel } from '@sentinels/models';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';

import { SignUpComponent } from '../../../../../../../gth/src/app/features/auth/views/sign-up/sign-up.component';

export interface PostRsvpSignUpDialogContract {
  event: GthEventItemModel,
  user: GthUnregisteredUserModel,
}

const SUCCESS_ICON = () => 'assets/icons/ribbon.svg';

@Component({
  selector: 'gth-post-rsvp-sign-up-dialog',
  standalone: true,
  imports: [
    JsonPipe,
    MatIconModule,
    SignUpComponent,
    MatButtonModule,
    MatDialogModule,
  ],
  templateUrl: './post-rsvp-sign-up-dialog.component.html',
  styleUrl: './post-rsvp-sign-up-dialog.component.scss',
})
export class PostRsvpSignUpDialogComponent {
  isSigningUp = signal(false);

  successIcon = SUCCESS_ICON();

  constructor(
    public dialogRef: MatDialogRef<PostRsvpSignUpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PostRsvpSignUpDialogContract,
  ) { }

  onSignUpButtonClick() {
    this.isSigningUp.set(true);
  };

  onSignUp() {
    this.dialogRef.close();
  };
}
