<div>
  <h3>Description</h3>
  <div class="description" [innerHTML]="description() ?? 'This event has no description.' | sanitizeHtml"></div>
</div>

<!-- TODO(srevier): Design fallback if no joiners -->
@if (vm().getAllParticipants().length) {
  <div>
    <h3>Participants</h3>
    <gth-event-participants
      [vm]="vm()"
      [user]="user()"
    ></gth-event-participants>
  </div>
}

@if (showCost()) {
  <div class="cost-container">
    @if (vm()?.ticketLevels?.length && vm()?.event?.hasEventSlots()) {
      <h3>Ticket levels</h3>

      <mat-chip-listbox [selectable]="false" [multiple]="false" [value]="null"
        (change)="vm().event.setSelectedTicketLevel($event.value)">
        @for (ticketLevel of vm().ticketLevels; track ticketLevel.name) {
        <mat-chip-option (click)="vm().event.setSelectedTicketLevel(ticketLevel)" [value]="ticketLevel"
          [selectable]="vm().event.selectedTicketLevel?.name !== ticketLevel?.name">
          {{ ticketLevel.name }} - {{ ticketLevel.cost | currency:'USD' }}
        </mat-chip-option>
        }
      </mat-chip-listbox>
    } @else if (vm().cost) {
      <h3>Cost</h3>
      <p class="secondary-text">{{ vm().cost | currency:'USD' }}</p>
    }
  </div>
}

<div>
  <h3>Share</h3>
  <gth-social-share
    [appName]="appName()"
    [url]="url()"
    [preview]="preview()"
  ></gth-social-share>
</div>

@if (!preview()) {
  <div class="more-events">
    <h3>More events from this user</h3>
    <app-carousel [hasControls]="false">
      @for (obj of eventsFromUser(); track obj.event.id) {
        @if (obj.event.id !== vm().event.id) {
          <app-event-card
            [event]="obj.event"
            [numberResponded]="obj.joiners.length"
            [numberNeeded]="obj.event.getPlayerCount(obj.joiners).totalNeeded"
            [participants]="obj.joiners"
          ></app-event-card>
        }
      }
      <app-event-card
        [generalCard]="true"
      ></app-event-card>
    </app-carousel>
  </div>
}
