<mat-stepper #stepper orientation="vertical" linear="true">
  <mat-step label="Confirm Your RSVP">
    <gth-button-menu class="main-action-btns"
                      [options]="rsvpMenuOptions()"
                      (itemClick)="onRsvpLevelChange($event)">
      {{ getGthButtonInfo(vm()).text }}
    </gth-button-menu>
    <br/>

    <button
      matStepperNext
      mat-button
      color="primary"
      class="step-btn"
    >
      Next
    </button>
  </mat-step>

  @if (!this.isMarkedAsNotGoing) {
    <mat-step label="Select Attendees">
      @if (rsvpStatus === 'Not Playing') {
        Change your RSVP status above to choose an open slot.
      }
      <div class="slot-container">
        @if (this.rsvpStatus !== 'Not Playing') {
          <gth-rsvp-slot-management
            [eventSlots]="vm().event.getEventSlotsFromGroup()"
            [inputEventJoiners]="rsvpInfo()"
            [eventRequiresGuestInformation]="vm().event.requireGuestInformation"
            [ticketLevels]="vm().event.ticketLevels"
            [slotsFormArray]="slotsFormArray"
            [participants]="vm().joiners"
            [isBasic]="vm().event.isBasic"
            [hasGroups]="vm().event.hasGroups"
          />
        }

        <br/>

        <button mat-button matStepperPrevious
                color="accent" class="step-btn">Back</button>

        <button mat-button matStepperNext
                class="step-btn" color="primary">Next</button>
      </div>
    </mat-step>
  }

  @if (this.vm().event.hasTeamsForEventsOnly) {
    <mat-step label="Select Team for Event">
      <h4>Select Existing Teams</h4>
      <mat-form-field>
        <mat-label>Teams</mat-label>
        <mat-select 
          [disabled]="createNewEventOnlyTeam.value || freeAgentCheckbox?.checked" 
          [(ngModel)]="eventOnlyTeamSelect" 
          (change)="onTeamSelected(['deleteCreate'])"
          #selectEventOnlyTeam
        >
          @for (team of this.vm().getAllEventOnlyTeams(); track team) {
            @if(team !== 'N/A'){
              <mat-option  [value]="team">{{ team }}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <br/>
      <mat-form-field>
        <mat-label>Create a New Team...</mat-label>
        <input 
          [disabled]="!!selectEventOnlyTeam.value || freeAgentCheckbox?.checked" 
          (change)="onTeamSelected(['deleteSelect'])"
          #createNewEventOnlyTeam 
          matInput 
          [(ngModel)]="eventOnlyTeamCreate" 
          placeholder="Ex. My new Team"/>
      </mat-form-field>
      <br/>
      <mat-checkbox 
        #freeAgentCheckbox 
        [checked]="this.getMyself(this.vm()?.joiners)?.eventTeamsOnly === 'N/A'"
        (change)="onTeamSelected(['deleteCreate', 'deleteSelect'])">Register as a Free Agent</mat-checkbox>

    @if (!hasValidEventOnlyTeam() && !freeAgentCheckbox?.checked) {
      <mat-error>Please Enter a valid team name.</mat-error>
    }
    </mat-step>
  }

  @if (!!vm().event.survey && !this.isMarkedAsNotGoing) {
    <mat-step label="Questionnaire">
      <gth-answer-questionnaire
        [eventSurvey]="vm().event.survey"
        [surveyAnswers]="getMyselfFromAllJoiners()?.surveyAnswers"/>
      <button matStepperNext mat-button
          color="primary" class="step-btn">Next</button>
    </mat-step>
  }

  <mat-step label="Save Your RSVP Choice!">

    @if (isGuestProfile()) {
      <gth-guest-form></gth-guest-form>
    }

    <button mat-flat-button type="button"
            color="primary" class="step-btn"
            (click)="onJoinEvent()"
            [disabled]="disableSaveBtn"
            >
      Click here to Save
    </button>

    <br/>

    <button color="accent" mat-button matStepperPrevious>Back</button>

    <mat-list role="list">
      @if (preview()) {
        <mat-list-item role="listitem">
          <p>
            <mat-icon fontIcon="warning" />
            You cannot RSVP while previewing an event.
          </p>
        </mat-list-item>
      } @else {
        @if (selfCountForEvent > 1) {
          <mat-list-item role="listitem">
            <p>
              <mat-icon fontIcon="warning" />
              Please make sure you only mark yourself as attending once.
            </p>
          </mat-list-item>
        }
        @if (!hasValidRsvp || rsvpForm.invalid) {
          <mat-list-item role="listitem">
            <p>
              <mat-icon fontIcon="warning" />
              Please make sure you have valid RSVP selections.
            </p>
          </mat-list-item>
        }
        @if (!isDirty) {
          <mat-list-item role="listitem">
            <p>
              <mat-icon fontIcon="warning" />
              You can save your RSVP only if changes are made.
            </p>
          </mat-list-item>
        }
        @if (!hasValidSurvey) {
          <mat-list-item role="listitem">
            <p>
              <mat-icon fontIcon="warning" />
              You have errors in your survey/questionaire.
            </p>
          </mat-list-item>
        }
  
        @if (!hasValidEventOnlyTeam() && !this.freeAgentCheckbox?.checked) {
          <mat-list-item role="listitem">
            <p>
              <mat-icon fontIcon="warning" />
              Please set your team for this event.
            </p>
          </mat-list-item>
        }
  
        @if (guestForm()?.formInvalid()) {
          <mat-list-item role="listitem">
            <p>
              <mat-icon fontIcon="warning" />
              Please provide valid contact information.
            </p>
          </mat-list-item>
        }
      }
    </mat-list>
  </mat-step>
</mat-stepper>