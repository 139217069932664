import {
  AbstractControl, FormArray, FormControl, FormGroup,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { EventItemTypes } from '@index/enums/event-item-type';
import { SkillLevel } from '@index/enums/skill-level';
import {
  EventItem,
  EventItemDuration,
  EventItemFormGroup,
  EventSlot,
  EventSlotFormGroup,
  EventSlotGroup,
  EventSlotGroupFormGroup,
  EventSurvey, EventTicketLevel,
  EventTicketLevelFormGroup,
  Location, SurveyQuestionFormGroup, SurveySelectionFormGroup,
  Team,
  UnregisteredTeamInfo,
  User,
} from '@index/interfaces';
import firebase from 'firebase/compat/app';

import { GthEventItemModel } from './event-item';

export const EVENT_FORM_ERRORS = {
  'general.title': 'Title is required',
  'general.gameType': 'Activity is required',
  'general.location': 'Missing location for event',
  'teams.teamName': 'You must select a team for your event',
  'participants.minimumNeeded': 'Minimum number of participants is required',
  'participants.hoursToCancelBefore': 'Hours to cancel before is required',
  'details.id': 'Custom URL is invalid',
  'details.survey': 'Survey needs at least one question',
  'details.survey.questions': 'Be sure to complete each question',
};

export class GthEventItemForm {
  locationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const online = this._eventForm?.get('general.online')?.value;
      const location = control.value;
      return !online && !location ? { 'locationRequired': true } : null;
    };
  }

  teamValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hostTeamEvent = this._eventForm?.get('teams.hostTeamEvent').value;
      return hostTeamEvent && !control.value ? { 'teamRequired': true } : null;
    };
  }

  minimumValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasMinimumNeeded = this._eventForm?.get('participants.hasMinimumNeeded')?.value;
      return hasMinimumNeeded && control.value === null ? { 'minimumRequired': true } : null;
    };
  }

  eventSlotsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isBasic = this._eventForm?.get('participants.isBasic')?.value;
      return isBasic && control.value.length === 0 ? { 'slotsRequired': true } : null;
    };
  }
  /**
   * Form Group
   */
  get formGroup() {
    return this._eventForm;
  }

  /**
   * Returns true if the form is disabled
   */
  get disabled() {
    return this._eventForm.disabled;
  }

  /**
   * Returns true if the form is valid
   */
  get valid() {
    return this.validLocation &&
      !this.formGroup.get('id')?.errors;
  }


  get validLocation() {
    return this.location || this.online;
  }

  /**
   * Type of event being held
   */
  get gameType() {
    return this._gameType;
  }
  set gameType(val: string) {
    this._gameType = val;
    this.setFieldValue('general.gameType', val);
  }

  /**
   * Allow Participants to bring guests
   */
  get allowParticipantGuests() {
    return this.getFieldValue('participants.allowParticipantGuests');
  }
  set allowParticipantGuests(val: boolean) {
    this.setFieldValue('participants.allowParticipantGuests', val);
  }

  /**
   * Banner for the Game
   */
  get banner() {
    return this.getFieldValue('general.banner') ?? '';
  }
  set banner(val: string) {
    this.setFieldValue('general.banner', val);
  }

  /**
   * id for the Game
   */
  get id() {
    return this.getFieldValue('details.id');
  }
  set id(val: string) {
    this.setFieldValue('details.id', val);
  }

  /**
   * Title for the Game
   */
  get title() {
    return this.getFieldValue('general.title');
  }
  set title(val: string) {
    this.setFieldValue('general.title', val);
  }

  /**
   * Skill Level for the Game
   */
  get skillLevel() {
    return this.getFieldValue('participants.skillLevel');
  }
  set skillLevel(val: SkillLevel | undefined) {
    this.setFieldValue('participants.skillLevel', val);
  }

  /**
   * Send feedback email after the event
   */
  get sendFeedbackEmailAfter() {
    return this.getFieldValue('details.sendFeedbackEmailAfter');
  }
  set sendFeedbackEmailAfter(val: boolean) {
    this.setFieldValue('details.sendFeedbackEmailAfter', val);
  }

  /**
   * Ticket levels for event
   */
  get ticketLevels() {
    return this.formGroup.controls.details.controls.ticketLevels.value as EventTicketLevel[];
  }
  set ticketLevels(val: EventTicketLevel[]) {
    const formArray = new FormArray<FormGroup<EventTicketLevelFormGroup>>([]);
    for (const ticketLevel of val) {
      const formGroup = new FormGroup<EventTicketLevelFormGroup>({
        name: new FormControl<string>(ticketLevel.name, Validators.required),
        cost: new FormControl<number>(
          ticketLevel.cost,
          [Validators.required, Validators.min(0), Validators.max(999999)],
        ),
        priceId: new FormControl<string>(ticketLevel.priceId),
        slotNumber: new FormControl(ticketLevel.slotNumber ?? null),
      });
      formArray.push(formGroup);
    }
    this.formGroup.controls.details.controls.ticketLevels = formArray;
  }

  get priceId() {
    return this.getFieldValue('details.priceId');
  }
  set priceId(val: string) {
    this.setFieldValue('details.priceId', val);
  }

  /**
   * Date the Event is scheduled start
   */
  get dateStart() {
    return this.getFieldValue('general.dateStart');
  }
  set dateStart(val: Date | undefined) {
    const duration = this.getFieldValue('general.duration');

    if (!duration) {
      this.duration = {
        hours: 1,
        minutes: 0,
      };
    }

    this.setFieldValue('general.dateStart', val);
  }

  /**
   * Duration of the event
   */
  get duration() {
    return this.getFieldValue('general.duration');
  }
  set duration(val: EventItemDuration | undefined) {
    this.setFieldValue('general.duration', val);
  }

  /**
   * Is the event online only
   */
  get online() {
    return this.getFieldValue('general.online');
  }
  set online(val: boolean) {
    this.setFieldValue('general.online', val);
  }

  /**
   * Location of the event
   */
  get location() {
    return this.getFieldValue('general.location');
  }
  set location(val: Location) {
    this.setFieldValue('general.location', val);
  }

  /**
   * Text description for the event
   */
  get locationDescription() {
    const location = this.location;
    if (location && location.formattedAddress) {
      return `${location.formattedAddress}`;
    }
    return 'Location not set';
  }

  /**
   * Name of the team
   */
  get teamName() {
    return this._teamName;
  }
  set teamName(val: string) {
    this._teamName = val;
    this.setFieldValue('teams.teamName', val);
  }

  get hostingTeam() {
    return this.formGroup.controls.teams.controls.hostingTeam.value;
  }
  set hostingTeam(val: string | UnregisteredTeamInfo) {
    this.formGroup.controls.teams.controls.hostingTeam.setValue(val);
  }

  get description() {
    return this.getFieldValue('general.description');
  }
  set description(val: string) {
    this.setFieldValue('general.description', val);
  }

  /**
   * Number of Players
   */
  get numberOfPlayers() {
    return this.getFieldValue('numberOfPlayers');
  }
  set numberOfPlayers(val: number) {
    this.setFieldValue('numberOfPlayers', val);
  }


  /**
   * Cost per Player to play
   */
  get cost() {
    return this.getFieldValue('details.cost');
  }
  set cost(val: number) {
    this.setFieldValue('details.cost', val);
  }

  /**
   * Equipment is needed to play
   */
  get equipmentNeeded() {
    return this.getFieldValue('details.equipmentNeeded');
  }
  set equipmentNeeded(val: string[]) {
    this.setFieldValue('details.equipmentNeeded', val);
  }

  /**
   * Equipment being typed in to add to equipment needed to play
   *
   * UI Only Property
   */
  get equipmentNeededItem() {
    return this.getFieldValue('details.equipmentNeededItem');
  }
  set equipmentNeededItem(val: string) {
    this.setFieldValue('details.equipmentNeededItem', val);
  }

  /**
   * Days to wait before making the event private
   */
  get makePublicAfter() {
    return this.getFieldValue('details.makePublicAfter');
  }
  set makePublicAfter(val: number | null) {
    this.setFieldValue('details.makePublicAfter', val);
  }

  /**
   * Creator Approval Needed checkbox
   */
  get creatorApprovalNeeded(): boolean {
    return this.getFieldValue('participants.creatorApprovalNeeded') ?? false;
  }
  set creatorApprovalNeeded(val: boolean) {
    this.setFieldValue('participants.creatorApprovalNeeded', val);
  }

  /**
   * Cost per minimum number needed to play
   */
  get minimumNeeded() {
    return this.formGroup.controls.participants.controls.minimumNeeded.value;
  }

  set minimumNeeded(val: number) {
    this.formGroup.controls.participants.controls.minimumNeeded.setValue(val);
  }

  /**
   * Cost per minimum number needed to play
   */
  get hoursToCancelBefore() {
    return this.formGroup.controls.participants.controls.hoursToCancelBefore.value;
  }

  set hoursToCancelBefore(val: number) {
    this.formGroup.controls.participants.controls.hoursToCancelBefore.setValue(val);
  }

  get value() {
    if (!this.currentUser) {
      return undefined;
    }
    const value = this._eventForm.getRawValue();
    const eventSlotGroup = value.participants.eventSlotGroup;
    let mapESG: EventSlotGroup[] = [];
    if (eventSlotGroup[0].slots?.length) {
      mapESG = eventSlotGroup.map((group) => {
        let slots = [];
        if (group.slots) {
          let number = 0;
          slots = group.slots.map((slot) => {
            number += 1;
            slot.number = number;
            return slot;
          });
        }
        return { ...group, slots };
      });
    } else mapESG = eventSlotGroup;

    if (value.participants.isBasic) {
      mapESG = [
        {
          date: firebase.firestore.Timestamp.now(),
          updatedAt: firebase.firestore.Timestamp.now(),
          slots: [
            {
              updatedAt: firebase.firestore.Timestamp.now(),
              number: 1,
              name: 'General Admission',
              capacity: value.participants.basicParticipantAmount ?? 0,
              groupsList: [],
            },
          ],
        },
      ];
    }

    if (this.currentTeam && !this.hostingTeam) {
      value.teams.hostingTeam = this.currentTeam.id;
      value.teams.teamName = this.currentTeam.name;
    }

    const event: Partial<EventItem> = {
      creator: this.currentUser.uid!,
      title: value.general.title,
      gameType: value.general.gameType,
      isPrivate: value.general.privacy === 'private',
      banner: value.general.banner,
      dateStart: value.general.dateStart,
      duration: value.general.duration,
      backgroundColor: value.general.backgroundColor ?? '',
      theme: value.general.theme,
      online: value.general.online,
      location: value.general.location,
      description: value.general.description,
      teamName: value.teams.teamName,
      hostingTeam: value.teams.hostingTeam,
      isBasic: value.participants.isBasic,
      groups: value.participants.groups,
      eventTeamsOnly: value.teams.eventTeamsOnly,
      minimumNeeded: value.participants.minimumNeeded,
      allowParticipantGuests: value.participants.allowParticipantGuests,
      hoursToCancelBefore: value.participants.hoursToCancelBefore,
      skillLevel: value.participants.skillLevel,
      allowMaybeRsvp: value.participants.allowMaybeRsvp,
      requireGuestInformation: value.participants.requireGuestInformation,
      allowUnregistered: value.participants.allowUnregistered,
      creatorApprovalNeeded: value.participants.creatorApprovalNeeded ?? false,
      cost: value.details.cost ?? 0,
      ticketLevels: value.details.ticketLevels,
      priceId: value.details.priceId ?? '',
      equipmentNeeded: value.details.equipmentNeeded,
      makePublicAfter: value.details.makePublicAfter,
      sendFeedbackEmailAfter: value.details.sendFeedbackEmailAfter ?? false,
      id: value.details.id,
      survey: value.details.survey,
      updated: firebase.firestore.Timestamp.now(),
      rated: false,
      joiners: [], // todo(ray):Change
      eventSlotGroup: mapESG,
    };
    return new GthEventItemModel(event.id, event as EventItem);
  }

  get generalValid() {
    const generalCtrl = this._eventForm.controls.general;

    if (generalCtrl.pristine) return true;
    if (generalCtrl.invalid) return false;

    const hasLocation = this.location && this.location.lat && this.location.lng;
    if (!this.online && !hasLocation) {
      return false;
    }

    return true;
  }

  get dirty() {
    return this._eventForm.dirty;
  }

  get eventType() {
    return this.getFieldValue('general.eventType');
  }
  set eventType(val: EventItemTypes) {
    this.setFieldValue('general.eventType', val);
  }

  set survey(val: EventSurvey) {
    this.formGroup.controls.details.controls.survey.patchValue(val);

    const formArray = new FormArray<FormGroup<SurveyQuestionFormGroup>>([]);
    val.questions.forEach((question) => {
      const formGroup = new FormGroup({
        text: new FormControl(question.text, Validators.required),
        required: new FormControl(question.required),
        page: new FormControl(question.page),
        answerType: new FormControl(question.answerType, Validators.required),
        selections: new FormArray([]),
      });
      if (question.selections) {
        const selectionFormArray = new FormArray<FormGroup<SurveySelectionFormGroup>>([]);
        question.selections.forEach((selection) => {
          const selectionFormGroup = new FormGroup({
            value: new FormControl(selection.value, Validators.required),
          });
          selectionFormArray.push(selectionFormGroup);
        });
        formGroup.controls.selections = selectionFormArray;
      }
      formArray.push(formGroup);
    })
    this.formGroup.controls.details.controls.survey.controls.questions = formArray;
  }

  get survey() {
    return this.getFieldValue('details.survey');
  }

  get allowMaybeRsvp() {
    return this.formGroup.controls.participants.controls.allowMaybeRsvp.value;
  }
  set allowMaybeRsvp(val: boolean) {
    this.formGroup.controls.participants.controls.allowMaybeRsvp.setValue(val);
  }

  get isBasic() {
    return this.formGroup.controls.participants.controls.isBasic.value;
  }
  set isBasic(val: boolean) {
    this.formGroup.controls.participants.controls.isBasic.setValue(val);
  }

  get isPrivate() {
    return this.formGroup.controls.general.controls.privacy.value === 'private';
  }
  set isPrivate(val: boolean) {
    this.formGroup.controls.general.controls.privacy.setValue(val ? 'private' : 'public');
  }

  get allowUnregistered() {
    return this.formGroup.controls.participants.controls.allowUnregistered.value;
  }
  set allowUnregistered(val: boolean) {
    this.formGroup.controls.participants.controls.allowUnregistered.setValue(val);
  }

  get error() {
    if (this.valid) {
      return '';
    }
    const errors = [];

    const erroredControlPaths = [];

    Object.keys(this._eventForm.controls).forEach((key) => {
      let controlPath = key;
      Object.keys(this._eventForm.controls[key].controls).forEach((subKey) => {
        controlPath = `${key}.${subKey}`;
        const control = this._eventForm.get(controlPath);
        if (control?.invalid) {
          erroredControlPaths.push(controlPath);
        }
      });
    });

    erroredControlPaths.forEach((controlPath) => {
      if (EVENT_FORM_ERRORS[controlPath]) {
        errors.push(EVENT_FORM_ERRORS[controlPath]);
      }
    });

    const survey = this.formGroup.get('details.survey')?.value;
    if (survey) {
      if (survey.questions.length === 0) {
        errors.push('Survey needs at least one question.');
      }

      const malformed = survey.questions.find((q: any) => q.text.length < 2);
      if (malformed) {
        errors.push('Be sure to complete each question.');
      }
    }

    return errors;
  }
  private _eventForm = new FormGroup<EventItemFormGroup>({
    general: new FormGroup({
      title: new FormControl(
        '',
        [Validators.required, Validators.maxLength(150)],
      ),
      gameType: new FormControl(null, Validators.required),
      dateStart: new FormControl(null, Validators.required),
      location: new FormControl(null, this.locationValidator()),
      duration: new FormControl(null, Validators.required),
      online: new FormControl(false),
      privacy: new FormControl('public'),
      description: new FormControl(null),
      banner: new FormControl(null),
      theme: new FormControl(null),
      backgroundColor: new FormControl(null),
      days: new FormControl(null),
    }),
    teams: new FormGroup({
      hostTeamEvent: new FormControl(false),
      hostingTeam: new FormControl(null, this.teamValidator()),
      teamName: new FormControl('', [Validators.maxLength(100), this.teamValidator()]),
      eventTeamsOnly: new FormControl(false),
      eventType: new FormControl(EventItemTypes.Pickup),
    }),
    participants: new FormGroup({
      groups: new FormArray([]),
      eventSlotGroup: new FormArray([this.createEventSlotGroupFormGroup()]),
      isBasic: new FormControl(true),
      basicParticipantAmount: new FormControl(0),
      allowUnregistered: new FormControl(false),
      requireGuestInformation: new FormControl(false),
      allowMaybeRsvp: new FormControl(false),
      hasMinimumNeeded: new FormControl(false),
      minimumNeeded: new FormControl(null, [Validators.min(1), this.minimumValidator()]),
      hoursToCancelBefore: new FormControl(
        null, [Validators.min(1), Validators.max(72), this.minimumValidator()],
      ),
      skillLevel: new FormControl(SkillLevel.Any),
      allowParticipantGuests: new FormControl(false),
      creatorApprovalNeeded: new FormControl(false),
    }),
    details: new FormGroup({
      cost: new FormControl(
        null,
        [Validators.min(0), Validators.max(999999)],
      ),
      ticketLevels: new FormArray([]),
      equipmentNeeded: new FormControl(null),
      equipmentNeededItem: new FormControl(''),
      sendFeedbackEmailAfter: new FormControl(false),
      id: new FormControl(
        '',
        [Validators.pattern('^[a-zA-Z0-9-_]*$')],
      ),
      priceId: new FormControl(null),
      makePublicAfter: new FormControl(0),
      survey: new FormGroup({
        description: new FormControl(''),
        questions: new FormArray([]),
        required: new FormControl(false),
      }),
    }),
  });

  private currentUser?: User;
  private currentTeam?: Team | UnregisteredTeamInfo;
  private _gameType = '';
  private _teamName = '';

  greaterThanZeroValidator(control: FormControl) {
    const value = control.value;
    if (value === null || value === '' || value > 0) {
      return null;
    } else {
      return { invalid: true };
    }
  }

  slotsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const slots = control.value || [];
      const names = new Set<string>();

      for (const slot of slots) {
        // Check if the name is empty or already exists
        if (!slot.name || names.has(slot.name)) {
          return { invalidName: true };
        }
        names.add(slot.name);

        // Check if the capacity is not a positive number
        if (slot.capacity <= 0 || isNaN(slot.capacity)) {
          return { invalidCapacity: true };
        }
      }

      return null; // Validation passed
    };
  }
  createEventSlotGroupFormGroup(): FormGroup<EventSlotGroupFormGroup> {
    // Create a new form control for the date field
    const dateControl = new FormControl(null);

    // Create a new form control for the updatedAt field
    const updatedAtControl = new FormControl(null);

    // Create a new form control for the slots field
    const slotsControl = new FormArray<FormGroup<EventSlotFormGroup>>([]);
    // Todo(rkara): Wire slot validator

    // Create a new form group for the EventSlotGroupFormGroup
    return new FormGroup<EventSlotGroupFormGroup>({
      date: dateControl,
      updatedAt: updatedAtControl,
      slots: slotsControl,
    });
  }

  addGroupFormGroup(): FormGroup {
    const name = new UntypedFormControl(null, Validators.required);

    return new FormGroup({
      name,
    });
  }

  setUser(user: User | undefined) {
    this.currentUser = user;
  }

  setTeam(team: Team | UnregisteredTeamInfo | undefined) {
    this.currentTeam = team;

    if ((team as Team).sport) {
      this.gameType = (team as Team).sport;
      return;
    }

    if ((team as UnregisteredTeamInfo).gameType) {
      this.gameType = (team as UnregisteredTeamInfo).gameType;
      return;
    }
  }

  setEvent(val: GthEventItemModel | undefined) {
    if (!val) {
      this._eventForm.reset();
    } else {
      this.id = val.id;
      this.title = val.title;
      this.dateStart = val.dateStart;
      this.duration = val.duration;
      this.location = val.location;
      this.gameType = val.gameType;
      this.teamName = val.teamName;
      this.hostingTeam = val.hostingTeam;
      this.minimumNeeded = val.minimumNeeded;
      this.hoursToCancelBefore = val.hoursToCancelBefore;
      this.eventType = val.eventType;
      this.cost = val.cost ? val.cost : 0;
      this.equipmentNeeded = val.equipmentNeeded;
      this.online = val.online ?? false;
      this.makePublicAfter = val.makePublicAfter;
      this.allowParticipantGuests = val.allowParticipantGuests;
      this.description = val.description;
      this.banner = val.banner ?? '';
      this.skillLevel = val.skillLevel;
      this.sendFeedbackEmailAfter = val.sendFeedbackEmailAfter;
      this.ticketLevels = val.ticketLevels;
      this.priceId = val.priceId;
      this.setEventSlotGroup(val.eventSlotGroup);
      if (val.survey) {
        this.survey = val.survey;
      }
      this.allowMaybeRsvp = val.allowMaybeRsvp ?? false;
      this.isBasic = val.isBasic;
      this.creatorApprovalNeeded = val.creatorApprovalNeeded;
      this.allowUnregistered = val.allowUnregistered;
      this.isPrivate = val.isPrivate;
    }
  }

  clearLocation() {
    this.location = { lat: 0, lng: 0, formattedAddress: '' };
  }

  enable() {
    this._eventForm.enable();
  }

  disable() {
    this._eventForm.disable();
  }

  disableLocationCtrl() {
    const locationCtrl = this._eventForm.get('location');
    if (locationCtrl) {
      locationCtrl.disable();
    }
  }


  /**
   * Adds equipment item to needed equipment list
   * @param {MatChipInputEvent} evt Chip Event
   */
  addEquipmentItem(evt: MatChipInputEvent) {
    const list = this.addItemToList(evt, this.equipmentNeeded, 'equipmentNeededItem');
    this.equipmentNeeded = list;
  }

  /**
   * Removes equipment item from needed equipment list
   * @param {string} equipment equipment item
   */
  removeEquipmentItem(equipment: string) {
    const list = this.removeItemFromList(this.equipmentNeeded, equipment);
    this.equipmentNeeded = list;
  }

  private getFieldValue(fieldName: string) {
    const ctrl = this._eventForm.get(fieldName);
    return ctrl ? ctrl.value : undefined;
  }

  private setFieldValue(fieldName: string, val: unknown) {
    const ctrl = this._eventForm.get(fieldName);
    if (ctrl) {
      ctrl.setValue(val);
    }
  }

  private addItemToList(evt: MatChipInputEvent, listItems: string[], formCtrlName: string) {
    if (!listItems) {
      listItems = [];
    }
    const value = (evt.value || '').trim();
    listItems.push(value.trim());
    const inputCtrl = this.formGroup.get(formCtrlName);
    if (inputCtrl) {
      inputCtrl.setValue('');
    }
    return listItems;
  }

  private removeItemFromList(listItems: string[], value: string) {
    if (!listItems) {
      return [];
    }
    const index = listItems.indexOf(value);

    if (index >= 0) {
      listItems.splice(index, 1);
    }
    return listItems;
  }

  private setEventSlotGroup(eventSlotGroup: EventSlotGroup[]) {
    const ctrl = this._eventForm.controls.participants.controls.eventSlotGroup;
    if (!ctrl) return;
    const disabled = true;
    const groupsToFormArray = (groups: string[]): FormArray<FormControl<string>> => {
      return new FormArray(groups.map((g) => new FormControl({ value: g, disabled })));
    };
    const slotsToFormArray = (
      slots: EventSlot[],
    ): FormArray<FormGroup<EventSlotFormGroup>> => {
      return new FormArray(slots.map((s) => {
        return new FormGroup<EventSlotFormGroup>({
          name: new FormControl(
            { value: s.name, disabled: !!s.groupsList.length },
            Validators.required,
          ),
          capacity: new FormControl(
            { value: s.capacity, disabled: !!s.groupsList.length },
            Validators.required,
          ),
          applyToDate: new FormControl({ value: s.updatedAt, disabled }),
          updatedAt: new FormControl({ value: s.updatedAt, disabled }),
          number: new FormControl({ value: s.number, disabled }),
          groupsList: groupsToFormArray(s.groupsList),
        });
      }));
    };
    const eventSlotGroupsToFormGroups = eventSlotGroup.map((eventSlotGroup) => {
      return new FormGroup<EventSlotGroupFormGroup>({
        date: new FormControl({ value: eventSlotGroup.date, disabled }),
        updatedAt: new FormControl({ value: eventSlotGroup.updatedAt, disabled }),
        slots: slotsToFormArray(eventSlotGroup.slots),
      });
    });
    this._eventForm.controls.participants.controls.eventSlotGroup.clear();
    eventSlotGroupsToFormGroups.forEach((eventSlotGroupFormGroup) => {
      this._eventForm.controls.participants.controls.eventSlotGroup
        .push(eventSlotGroupFormGroup);
      if (disabled) this._eventForm.controls.participants.controls.eventSlotGroup.disable();
    });
  }
}
