import { Component, input } from '@angular/core';

import { WallPostComponent } from '../../wall-post/wall-post.component';

@Component({
  selector: 'gth-chat-tab',
  standalone: true,
  imports: [
    WallPostComponent,
  ],
  templateUrl: './chat-tab.component.html',
  styleUrl: './chat-tab.component.scss',
})
export class ChatTabComponent {
  eventId = input<string>('');
}
