import { CommonModule, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { EventInfoDialogCloseMethod } from '@sentinels/enums';
import { GthEventItemModel, GthUserModel } from '@sentinels/models';
import { APP_STATE } from '@sentinels/state/state';
// TODO circular dependency
import { APP_ROUTES } from '@shared/helpers';
import { BehaviorSubject, Subscription } from 'rxjs';

import { JoinEventOptions } from '../../components/event-info/components/rsvp-steps/rsvp-steps.component';
import { EventInfoComponent } from '../../components/event-info/event-info.component';
import { EventInfoLoadEventItem } from '../../components/event-info/state/actions';
import { eventInfoReducer } from '../../components/event-info/state/reducers';
import { EventInfoViewModel } from '../../components/event-info/view-models/event-info-model';

export interface EventInfoDialogOpenContract {
  event: string;
  user: GthUserModel;
  team?: string;
  platform?: 'gth' | 'meh',
}

export interface EventInfoDialogCloseContract {
  useSocialMedia: boolean;
  selectedImage: ElementRef | null;
  closeMethod: EventInfoDialogCloseMethod;
}

@Component({
  selector: 'gth-event-info-dialog',
  templateUrl: './event-info-dialog.component.html',
  styleUrls: ['./event-info-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    EventInfoComponent,
    MatButtonModule,
    MatIconModule,
    NgIf,
    MatProgressSpinnerModule,
  ],
})
export class EventInfoDialogComponent implements OnInit {
  loading = true;
  private subscriptions = new Subscription();

  vm$? = new BehaviorSubject<EventInfoViewModel>(new EventInfoViewModel());

  get event() {
    return this.data ? this.data.event : null;
  }

  get user() {
    return this.data ? this.data.user : null;
  }

  get team() {
    return this.data ? this.data.team : null;
  }

  get platform() {
    return this.data ? this.data.platform : 'gth';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: EventInfoDialogOpenContract,
    private store: Store<APP_STATE>,
    private dialogRef: MatDialogRef<EventInfoDialogComponent>,
  ) {
    this.store.addReducer('eventInfoState', eventInfoReducer);
    this.store.dispatch(EventInfoLoadEventItem(
      { eventId: this.data.event },
    ));
  }

  ngOnInit() {
    const storeSub = this.store.select((state) => state).subscribe((results) => {
      const state = results.eventInfoState;
      if (!state || !this.vm$) return;
      this.vm$.next(new EventInfoViewModel(results.eventInfoState));
    });

    this.subscriptions.add(storeSub);
  }


  onJoinEvent(joinOptions: JoinEventOptions) {
    console.debug('EventInfoDialogComponent: join event');
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.SAVE));
  }

  onEditEvent(event: GthEventItemModel) {
    console.debug('EventInfoDialogComponent: edit event', event);
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.UPDATE));
  }

  onDeclineInvite(event: GthEventItemModel) {
    console.debug('EventInfoDialogComponent: decline invite to event', event);
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.LEAVE));
  }

  onCancelEvent(event: GthEventItemModel) {
    console.debug('EventInfoDialogComponent: cancel event', event);
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.CANCEL));
  }

  onLeaveEvent(event: GthEventItemModel) {
    console.debug('EventInfoDialogComponent: leave event', event);
    this.dialogRef.close(this.dialogCloseContract(EventInfoDialogCloseMethod.LEAVE));
  }

  dialogCloseContract(
    method: EventInfoDialogCloseMethod = EventInfoDialogCloseMethod.OTHER,
    useSocialMedia = false,
  ): EventInfoDialogCloseContract {
    return {
      useSocialMedia,
      selectedImage: null,
      closeMethod: method,
    };
  }

  onClose() {
    this.dialogRef.close();
  }
}
