import {
  AfterContentInit,
  Component, ContentChildren, effect,
Input, input,
Output, QueryList, ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { BehaviorSubject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

import { GthGoogleMapsService } from '../../services/google-maps.service';
import {
  GthGoogleMapMarkerComponent,
  GthGoogleMapMarkerItem,
} from './components/map-marker/google-map-marker.component';

const MAP_OPTIONS: google.maps.MapOptions = {
  styles: [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
};

@Component({
  selector: 'gth-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GthGoogleMapComponent implements AfterContentInit {
  private centerSubject = new BehaviorSubject<google.maps.LatLngLiteral>(undefined);

  @ViewChild(GoogleMap)
  private googleMap?: GoogleMap;

  @ContentChildren(GthGoogleMapMarkerComponent)
  private mapMarkers?: QueryList<GthGoogleMapMarkerComponent>;

  mapOptions = MAP_OPTIONS;

  latitude = input<number>(0);
  longitude = input<number>(0);

  @Input()
  zoom = 13;

  @Input()
  debounceTime = 500;

  @Output()
  readonly centerChange = this.centerSubject.asObservable().pipe(
    debounce(() => timer(this.debounceTime || 0)),
    distinctUntilChanged(),
  );

  markers?: GthGoogleMapMarkerItem[];

  center?: google.maps.LatLngLiteral;

  constructor(private mapService: GthGoogleMapsService) {
    effect(() => {
      if (this.latitude() && this.longitude() && this.mapMarkers) {
        this.setupMap();
      }
    });
  }

  ngAfterContentInit() {
    this.setupMap();
  }

  private setupMap() {
    if (!this.mapMarkers) {
      return;
    }
    this.markers = [];
    this.center = {
      lat: this.latitude(),
      lng: this.longitude(),
    };
    this.mapMarkers.forEach((marker: any) => {
      this.markers.push({
        options: {
          position: {
            lat: marker.latitude,
            lng: marker.longitude,
          },
          draggable: marker.markerDraggable,
        },
        item: marker.item,
        markerClick: marker.markerClick,
        markerDrag: marker.markerDrag,
      });
    });
  }

  onMarkerClick(marker: GthGoogleMapMarkerItem, event: google.maps.MapMouseEvent) {
    if (!marker.item) {
      return;
    }
    this.mapService.emit(marker.item);
  }

  onDragEnd(marker: GthGoogleMapMarkerItem, event: google.maps.MapMouseEvent) {
    if (!marker.markerDrag) {
      return;
    }
    marker.markerDrag.emit(event);
  }

  onCenterChanged() {
    if (!this.googleMap) {
      return;
    }
    const center = this.googleMap.getCenter();
    const location = {
      lat: center.lat(),
      lng: center.lng(),
    };
    const oldCenter = this.centerSubject.getValue();
    if (!oldCenter) {
      this.centerSubject.next(location);
      return;
    }

    if (oldCenter.lat !== location.lat || oldCenter.lng !== location.lng) {
      this.centerSubject.next(location);
    }
  }
}
