import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, ViewChild, viewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { ButtonMenuComponent, ButtonMenuOptions } from '@gth-legacy/directives/button-menu/button-menu.component';
import { ButtonMenuModule } from '@gth-legacy/directives/button-menu/button-menu.module';
import { EventJoiner, EventRsvpStatus, EventSlot } from '@index/interfaces';
import { EventJoinerModel } from '@index/models/event-joiner';
import { GthEventItemModel, GthUserModel } from '@sentinels/models';
import { UnregisteredAccountDetails, UnregisteredUserService } from '@sentinels/services/firebase/unregistered-user.service';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { take } from 'rxjs';

import { EventInfoViewModel } from '../../view-models/event-info-model';
import { AnswerQuestionnaireComponent } from '../answer-questionnaire/answer-questionnaire.component';
import { GuestFormComponent } from '../guest-form/guest-form.component';
import { RsvpSlotManagementComponent, SlotParticipant } from '../rsvp-slot-management/rsvp-slot-management.component';

const EVENT_ONLY_TEAM_FREE_AGENT_NAME_FN = () => 'N/A';

export class FormControlWithWarnings<TValue = any> extends FormControl<TValue> {
  warnings: { [key: string]: any };

  constructor(Value: TValue, validatorOrOpts?: any, asyncValidator?: any) {
    super(Value, validatorOrOpts, asyncValidator);
    this.warnings = {};
  }

  get hasWarnings() {
    return !!Object.keys(this.warnings).length;
  }
}

export interface JoinEventOptions {
  joiners: EventJoiner[],
  event: GthEventItemModel,
  status: EventRsvpStatus,
}

export interface RSVPFormGroup {
  rsvpStatus: FormControl<EventRsvpStatus>,
  slots: FormArray<FormGroup<SlotFormGroup>>,
  // questionnaireAnswers: FormControl<unknown[]>,
}

export interface SlotFormGroup {
  groupName?: FormControl<string>,
  eventSlot: FormControlWithWarnings<EventSlot>,
  slotParticipant: FormControl<SlotParticipant>,
  guestName: FormControl<string>,
  guestEmail: FormControl<string>,
}

const defaultButtonMenuOptions:ButtonMenuOptions[] = [
  { viewValue: 'Yes', value: EventRsvpStatus.PLAYING, disabled: false },
  { viewValue: 'No', value: EventRsvpStatus.NOT_PLAYING, disabled: false },
  { viewValue: 'Maybe', value: EventRsvpStatus.MAYBE, disabled: false },
];

@Component({
  selector: 'gth-rsvp-steps',
  templateUrl: './rsvp-steps.component.html',
  styleUrls: ['./rsvp-steps.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatStepperModule,
    ButtonMenuModule,
    AnswerQuestionnaireComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatCheckboxModule,
    GuestFormComponent,
    JsonPipe,
    RsvpSlotManagementComponent,
  ],
})
export class RsvpStepsComponent {
  @ViewChild(AnswerQuestionnaireComponent)
  questionnaireComponent?: AnswerQuestionnaireComponent;

  @ViewChild(ButtonMenuComponent) gthButton: ButtonMenuComponent;

  @ViewChild('stepper') stepper: any;
  @ViewChild('freeAgentCheckbox') freeAgentCheckbox: MatCheckbox;

  guestForm = viewChild(GuestFormComponent);

  isGuestProfile = input(true);

  vm = input<EventInfoViewModel>();
  user = input<GthUserModel>();
  rsvpInfo = input<EventJoinerModel[]>();
  preview = input(false);

  joinEvent = output<JoinEventOptions>();
  eventOnlyTeamSelect?: string;
  eventOnlyTeamCreate?: string;

  buttonMenuOptions: ButtonMenuOptions[] = defaultButtonMenuOptions;

  unregisteredUserUid = computed(() =>
    this.rsvpInfo()?.find((j) => j.isUnregisteredUser)?.player,
  );

  rsvpMenuOptions = computed(() => {
    const isEventFull = this.vm().playerCountObj.playersNeeded === 0;

    return defaultButtonMenuOptions.map((item) => {
      const allowMaybeRsvp = this.vm()?.event?.allowMaybeRsvp;

      if (item.value === EventRsvpStatus.PLAYING) {
        item.disabledText = isEventFull ? 'Event is full' : undefined;
      } else if (item.value === EventRsvpStatus.MAYBE && !allowMaybeRsvp) {
        item.disabled = true;
        item.disabledText = 'Not allowed';
      }
      return item;
    });
  });
  isWaitlisted = computed(() => {
    return this.vm().needParticipants;
  });

  rsvpForm = new FormGroup<RSVPFormGroup>({
    rsvpStatus: new FormControl(null),
    slots: new FormArray([]),
    // questionnaireAnswers: new FormControl(null),
  });
  get rsvpStatusCtrl() {
    return this.rsvpForm.controls.rsvpStatus;
  }
  get rsvpStatus() {
    return this.rsvpStatusCtrl.value;
  }
  get slotsFormArray() {
    return this.rsvpForm.controls.slots;
  }
  // get questionnaireAnswersCtrl() {
  //   return this.rsvpForm.controls.questionnaireAnswers;
  // }

  private unregisteredUser = inject(UnregisteredUserService);
  private safeStorage = inject(SrvSafeStorageService);
  private snackbar = inject(MatSnackBar);

  constructor() {
    effect(() => {
      const user = this.user();
      if (!user) return;

      const rsvpStatus = this.vm()
        .getCurrentUserRsvpStatus(user.uid, this.rsvpStatus);
      this.rsvpStatusCtrl.setValue(rsvpStatus);
      this.buttonMenuOptions = this.getButtonOptions();

      this.eventOnlyTeamSelect = this.getMyself(this.vm().joiners)?.eventTeamsOnly;
    });
  }

  getGthButtonInfo(vm:EventInfoViewModel) {
    if (!vm.getAllParticipants()?.length && !this.user() && !this.isGuestProfile()) {
      return { text: '...Waiting', rsvpStatus: null };
    }

    const caseToSwitchOn = this.rsvpStatus ||
    vm.getCurrentUserRsvpStatus(
      this.user()?.uid ?? this.unregisteredUserUid(), this.rsvpStatus,
    );

    switch (caseToSwitchOn) {
      case null:
        return { text: 'Choose RSVP', rsvpStatus: null };
      case EventRsvpStatus.PLAYING:
        return { text: 'Going', rsvpStatus: EventRsvpStatus.PLAYING };
      case EventRsvpStatus.NOT_PLAYING:
        return { text: 'Not Going', rsvpStatus: EventRsvpStatus.NOT_PLAYING };
      case EventRsvpStatus.MAYBE:
        return { text: 'Maybe', rsvpStatus: EventRsvpStatus.MAYBE };
      case EventRsvpStatus.ATTEMPTING:
        return { text: 'Waiting For Organizer', rsvpStatus: EventRsvpStatus.ATTEMPTING };
      default:
        return { text: '...Waiting', rsvpStatus: null };
    }
  }

  onRsvpLevelChange(status: EventRsvpStatus) {
    this.rsvpStatusCtrl.setValue(status);
    this.rsvpStatusCtrl.markAsDirty();
    this.buttonMenuOptions = this.getButtonOptions();

    if (status === EventRsvpStatus.NOT_PLAYING || this.vm().event.isBasic) {
      this.setInitialSlot();
    }
  }

  setInitialSlot(number: number = 0) {
    this.slotsFormArray.reset();
    this.slotsFormArray.clear();
    this.slotsFormArray.push(new FormGroup<SlotFormGroup>({
      eventSlot: new FormControlWithWarnings(this.vm().event.eventSlotGroup[0].slots[number]),
      slotParticipant: new FormControl(SlotParticipant.SELF),
      guestName: new FormControl(''),
      guestEmail: new FormControl(''),
    }));
  }

  getButtonOptions() {
    const isFullEvent = this.vm().playerCountObj.playersNeeded === 0;

    return defaultButtonMenuOptions.map((item)=>{
      if (isFullEvent && item.value === EventRsvpStatus.PLAYING) {
        item.disabledText = 'Join Waitlist';
        item.value = EventRsvpStatus.WAITLISTED;
      }
      return item;
    });
  }

  getJoiners() {
    return this.getJoinersToSave() || [{
      createdAt: new Date(),
      guestOfParticipant: false,
      isGuestUser: false,
      player: this.user()?.uid,
      rsvpStatus: EventRsvpStatus.NOT_PLAYING,
      slotName: 'General',
    }];
  }

  getMyself(joiners: EventJoiner[]) {
    const myself = joiners.find((j) => j.guestOfParticipant === false);
    if (myself) {
      myself.eventTeamsOnly = this.getEventOnlyTeamName() || null;

      if (this.isGuestProfile() && this.vm().allowUnregistered) {
        myself.isUnregisteredUser = true;
      }
    }
    return myself;
  }

  getMyselfFromAllJoiners() {
    return this.getMyself(this.vm().joiners);
  }

  getJoinersToSave() {
    let joiners: EventJoiner[] = [];
    if (this.rsvpStatusCtrl.value === EventRsvpStatus.NOT_PLAYING) {
      joiners.push(this.notPlayingRsvp);
    } else {
      joiners = this.getAllEventJoiners();
      const myself = this.getMyself(joiners);

      if (myself === undefined) joiners.push(this.notPlayingRsvp);
    }

    const myself = this.getMyself(joiners);
    myself.surveyAnswers = this.questionnaireComponent?.answers || [];

    return joiners;
  }

  getEventOnlyTeamName() {
    if (this.vm().hasTeamsForEventsOnly &&
    !this.eventOnlyTeamCreate &&
    !this.eventOnlyTeamSelect) return EVENT_ONLY_TEAM_FREE_AGENT_NAME_FN();

    return this.eventOnlyTeamCreate?
      this.eventOnlyTeamCreate : this.eventOnlyTeamSelect;
  }

  getAllEventJoiners(): EventJoiner[] {
    const slots = this.slotsFormArray.controls;

    return slots.map((slotControl) => {
      const rsvpStatus = slotControl.controls.eventSlot.warnings?.slotsFull ?
        EventRsvpStatus.WAITLISTED : this.rsvpStatusCtrl.value;
      const s = slotControl.value;

      return {
        player: this.user()?.uid,
        createdAt: new Date(),
        rsvpStatus,
        isGuestUser: undefined,
        slotNumber: s?.eventSlot?.number,
        guestOfParticipant: s.slotParticipant === SlotParticipant.GUEST,
        guestName: s.guestName ?? null,
        guestEmail: s.guestEmail ?? null,
        groupName: s.groupName ?? null,
      };
    });
  };

  get notPlayingRsvp() {
    return {
      createdAt: new Date(),
      guestOfParticipant: false,
      isGuestUser: false,
      player: this.user().uid,
      rsvpStatus: EventRsvpStatus.NOT_PLAYING,
      slotName: 'General',
      eventOnlyTeamsOnly: this.getEventOnlyTeamName(),
    };
  }

  getJoinersWithWaitlistStatus() {
    const numberNeededisGreaterThanZero =
      this.vm().totalParticipantsNeeded > 0;

    return this.getJoiners().map((joiner) => {
      return {
        ...joiner,
        rsvpStatus: numberNeededisGreaterThanZero ?
          joiner.rsvpStatus :
          EventRsvpStatus.WAITLISTED, ...numberNeededisGreaterThanZero &&
          { waitListDateTime: new Date() },
      };
    });
  }

  onJoinEvent() {
    const joiners = this.getJoinersWithWaitlistStatus();
    const joinEventOptions: JoinEventOptions = {
      joiners,
      event: this.vm().event,
      status: this.rsvpStatus,
    };

    if (this.isGuestProfile() && this.vm().allowUnregistered) {
      const details: UnregisteredAccountDetails = {
        email: this.guestForm().guestEmail(),
        fullName: this.guestForm().guestName(),
      };
      this.safeStorage.setItem('UNREGISTERED_CONTACT_INFO', JSON.stringify(details));

      this.unregisteredUser.createGuestAccount$(details).pipe(
        take(1),
      ).subscribe((uid) => {
        const participants = this.vm().getAllParticipants();
        if (participants.find((p) => p.player === uid)) {
          this.snackbar.open('You are already registered for this event');
          return;
        };

        const myself = joiners.find((j) => j.guestOfParticipant === false);
        myself.player = uid;
        this.joinEvent.emit(joinEventOptions);
      });

      return;
    }

    this.joinEvent.emit(joinEventOptions);
  }

  get selfCountForEvent() {
    return this.selfSelectedCount || 0;
  }

  get selfSelectedCount() {
    return this.slotsFormArray.value
      .filter((s) => s.slotParticipant === SlotParticipant.SELF)
      .length;
  }

  get isDirty() {
    if (!this.gthButton) return false;
    if (this.gthButton.changed &&
      this.rsvpStatusCtrl.value === EventRsvpStatus.NOT_PLAYING) return true;

    return this.rsvpForm.dirty;
  }

  get hasValidRsvp() {
    if (this.vm().event.isBasic) return true;

    const joiners = this.getAllEventJoiners() || [];
    if (this.rsvpStatusCtrl.value === undefined) return false;
    if (this.rsvpStatusCtrl.value === EventRsvpStatus.NOT_PLAYING) return true;
    if (!joiners.length) return false;

    const malformed = joiners.find((item) => {
      return item.guestOfParticipant === undefined ||
        item.slotNumber === undefined;
    });

    return !(!!malformed);
  }

  scrollIntoView() {
    if (!this.stepper) return;

    this.stepper._elementRef.nativeElement
      .scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  get disableSaveBtn() {
    return this.selfCountForEvent > 1 ||
      !this.isDirty ||
      !this.hasValidRsvp ||
      !this.vm().needParticipants ||
      !this.hasValidSurvey ||
      this.hasValidEventOnlyTeam() &&
      !!this.freeAgentCheckbox?.checked ||
      this.guestForm()?.formInvalid() ||
      this.rsvpForm.invalid ||
      this.preview();
  }

  get hasValidSurvey() {
    if (!this.questionnaireComponent || this.isMarkedAsNotGoing) {
      return true;
    }

    return !this.questionnaireComponent.hasErrors;
  }

  get isMarkedAsNotGoing() {
    return this.rsvpStatusCtrl && this.rsvpStatusCtrl.value === EventRsvpStatus.NOT_PLAYING;
  }

  get teamNameisTakenOrMalformed() {
    return !this.eventOnlyTeamSelect && !this.eventOnlyTeamCreate;
  }


  hasValidEventOnlyTeam() {
    if (!this.vm().hasTeamsForEventsOnly) return true;
    if (this.eventOnlyTeamCreate === EVENT_ONLY_TEAM_FREE_AGENT_NAME_FN()) return false;
    if (
      this.eventOnlyTeamCreate &&
      this.vm().getAllEventOnlyTeams().includes(this.eventOnlyTeamCreate)
    ) {
      return true;
    }

    if (this.eventOnlyTeamSelect && !this.freeAgentCheckbox?.checked||
      this.eventOnlyTeamCreate && !this.freeAgentCheckbox?.checked) return true;

    return !this.eventOnlyTeamSelect &&
      !this.eventOnlyTeamCreate &&
      !!this.freeAgentCheckbox?.checked;
  }

  onTeamSelected(items: string[]) {
    items.map((item)=>{
      if (item === 'deleteCreate') {
        this.eventOnlyTeamCreate = undefined;
      }

      if (item === 'deleteSelect') {
        this.eventOnlyTeamSelect = undefined;
      }
    });
  }
}
