<div class="buttons-container">
  <button
    mat-flat-button
    color="secondary"
    shareButton="facebook"
    [description]="shareDescription()"
    [url]="url"
    #fbBtn="shareButton"
    [disabled]="preview()"
  >
    <div>
      <div class="icon-container"><fa-icon [icon]="faFacebookF"></fa-icon></div>
      Facebook
    </div>
  </button>

  <button
    mat-flat-button
    color="secondary" 
    shareButton="twitter"
    [description]="shareDescription()"
    [url]="url"
    #twitterBtn="shareButton"
    [disabled]="preview()"
  >
    <div>
      <div class="icon-container"><fa-icon [icon]="faTwitter"></fa-icon></div>
      Twitter
    </div>
  </button>

  <button
    #copyLink
    mat-flat-button
    color="secondary" 
    shareButton="copy"
    [url]="url"
    [description]="shareDescription()"
    (click)="onCopyLink()"
    [disabled]="preview()"
  >
    <div>
      <div class="icon-container"><mat-icon class="icon">link</mat-icon></div>
      Copy Link
    </div>
  </button>
</div>