import { EventJoinerStatus, EventRsvpStatus } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';

import { CurrentState, EventInfoState } from '../state/state';

export interface ParticipantsDisplayInfo {
  displayName: string,
  isGuest: boolean,
  isUnregistered: boolean,
  rsvpStatus: EventRsvpStatus,
  userId?: string,
  slot: string,
}

// First View model.
export class EventInfoViewModel {
  isTeamEvent = false;
  isUserTeamAdmin = false;
  isCreator = false;

  get allParticipantsDisplayInfo() {
    const ju = this.eventInfoState?.joinersUsers;
    const joiners = ju.joiners;

    return joiners.map((joiner) => {
      const user = ju.users.find((user) => {
        return user?.id === joiner.player;
      });
      if (!user) return null;

      const info: ParticipantsDisplayInfo = {
        displayName: user.displayNameFallback,
        isGuest: joiner.guestOfParticipant,
        isUnregistered: joiner.isUnregisteredUser,
        rsvpStatus: joiner.rsvpStatus,
        userId: user.uid,
        slot: joiner.slotName,
      };

      return info;
    }).filter((info) => info !== null);
  }

  get totalCount() {
    return this.getAllParticipants().length;
  }

  getAllParticipants() {
    return this.getJoinersByStatus();
  }

  get id() {
    return this.event?.id ?? '';
  }

  get gameDescription() {
    return this.event?.description ?? '';
  }

  get banner() {
    return this.event?.banner ?? '';
  }

  get title() {
    return this.event?.title ?? '';
  }

  get dateStart() {
    return this.event?.dateStart ?? undefined;
  }

  private get duration() {
    return this.event?.duration ?? undefined;
  }

  get durationHours() {
    return this.duration ? this.duration.hours : 0;
  }

  get durationMinutes() {
    return this.duration ? this.duration.minutes : 0;
  }

  get cancelled() {
    return this.event?.cancelled ?? false;
  }


  get uniqueUsersFromEvent() {
    return this.uniqueEventUsersWithGuestCount;
  }

  get isGameInPast() {
    if (!this.dateStart) {
      return false;
    }
    const today = new Date();
    const endOfGame = new Date(this.dateStart);
    const newHours = endOfGame.getHours() + this.durationHours;
    const newMinutes = endOfGame.getMinutes() + this.durationMinutes;
    endOfGame.setHours(newHours, newMinutes);

    if (endOfGame.getTime() > today.getTime()) {
      return false;
    }
    return true;
  }

  get uniqueEventUsers(): (GthUserModel | GthUnregisteredUserModel)[] {
    return this.eventInfoState?.joinersUsers?.users || [];
  }

  get uniqueEventUsersWithGuestCount(): (GthUserModel | GthUnregisteredUserModel)[] {
    return this.uniqueEventUsers.filter(Boolean).map((user) => {
      user.metadata = {
        guestCount: this.getUserGuestCount(user.id),
      };
      return user;
    });
  }

  get eventWithGuestCount(): (GthUserModel | GthUnregisteredUserModel)[] {
    return this.uniqueEventUsers.map((user) => {
      user.metadata = {
        guestCount: this.getUserGuestCount(user.id),
      };
      return user;
    });
  }

  get isLoading() {
    if (!this.eventInfoState) return true;
    return this.eventInfoState.state === CurrentState.Loading ||
      this.eventInfoState.state === CurrentState.Pending;
  }

  get event() {
    return this.eventInfoState?.event;
  }

  get team() {
    return this.eventInfoState?.team;
  }

  get state() {
    return this.eventInfoState?.state;
  }


  get hasTeamsForEventsOnly() {
    return this.event?.hasTeamsForEventsOnly;
  }


  get equipmentNeeded() {
    return this.event?.equipmentNeeded ?? [];
  }

  get creatorApprovalNeeded() {
    return this.event?.creatorApprovalNeeded ?? false;
  }

  get allowUnregistered() {
    return this.event?.allowUnregistered ?? false;
  }

  get eventType() {
    return this.event?.gameType ?? '';
  }

  get gameType() {
    return this.event?.eventType ?? '';
  }

  get lat() {
    return this.event?.location?.lat ?? 47.620422;
  }

  get lng() {
    return this.event?.location?.lng ?? -122.349358;
  }

  get cost() {
    return this.event?.cost ?? 0;
  }

  get ticketLevels() {
    return this.event?.ticketLevels ?? [];
  }

  get ticketLevelPriceRange() {
    if (this.event?.ticketLevels?.length === 0) return 'Free';
    if (this.event?.ticketLevels.length === 1) return this.event?.ticketLevels[0].cost;

    let min = 0;
    let max = 0;
    this.event?.ticketLevels.map((level) => {
      min = level.cost <= min ? level.cost : min;
      max = level.cost >= max ? level.cost : max;
    });

    if (max === min) return max;
    return `${min === 0 ? 'Free' : min} - ${max}`;
  }

  get joinerUserKeys() {
    return Object.keys(this.eventInfoState.joinersUsers);
  }

  get errors() {
    const errors = [];

    if (typeof this.event?.cost === 'number' && this.event.cost !== 0) {
      errors.push('Payment is required');
    }

    return errors;
  }

  get online() {
    return this.event && this.event.online;
  }

  get address() {
    if (!this.event) {
      return '';
    }
    if (this.event.online) {
      return 'This is an online event';
    }
    return this.event.location ? this.event.location.formattedAddress : '';
  }

  get playerCountObj() {
    return this.event?.getPlayerCount(this.joiners) ||
      {
        participantsResponded: 0,
        playersNeeded: 0,
        totalNeeded: 0,
      };
  }

  get needParticipants() {
    if (!this.event) return false;

    const totalNeeded = this.playerCountObj.totalNeeded;
    return totalNeeded !== 0;
  }

  get joiners() {
    return this.eventInfoState?.joinersUsers?.joiners;
  }

  get totalParticipantsNeeded() {
    return this.playerCountObj.playersNeeded;
  }

  setIsCreator(user?: GthUserModel) {
    if (!this.event || !user) return;

    this.isCreator = this.event?.creator?.uid === user?.uid;
  }

  get canEditEvent() {
    if (this.isTeamEvent) {
      if (!this.isUserTeamAdmin) {
        return false;
      }
    } else if (!this.isCreator) {
      return false;
    }
    return true;
  }


  constructor(private eventInfoState?: EventInfoState) {}

  setTeamStatus(user: GthUserModel) {
    if (!this.team) {
      this.isTeamEvent = false;
      this.isUserTeamAdmin = false;
      return;
    }

    this.isTeamEvent = true;
    if (this.team.isUserAdmin(user)) {
      this.isUserTeamAdmin = true;
      return;
    }
    this.isUserTeamAdmin = false;
  }

  getJoinersByStatus(rsvpStatus?: EventRsvpStatus) {
    const joiners = this.eventInfoState?.joinersUsers.joiners;
    return rsvpStatus ? joiners.filter((joiner) => {
      return joiner.rsvpStatus === rsvpStatus;
    }) : joiners;
  }

  getAllEventOnlyTeams() {
    if (!this.event.hasTeamsForEventsOnly) return null;
    const teams = new Set<string>();

    this.joiners.map((joiner)=>{
      if (!joiner.eventTeamsOnly) return;
      teams.add(joiner.eventTeamsOnly);
    });

    return [...teams.values()];
  }

  getUserRsvpsAsJoiner(userId: string, onlySelf = true) {
    const joiners = this.eventInfoState?.joinersUsers?.joiners || [];
    return joiners?.filter((j) => {
      return j.player === userId && (onlySelf ? j.guestOfParticipant === false : true);
    });
  }

  getUserGuestCount(userId: string) {
    const guests = this.eventInfoState?.joinersUsers?.joiners?.filter((j) => {
      return j.player === userId && j.guestOfParticipant === true;
    });

    return guests ? guests.length : 0;
  }

  getCurrentUserRsvpStatus(userId: string, rsvpStatus: EventRsvpStatus) {
    if (!userId) {
      return EventRsvpStatus.NOT_PLAYING;
    }

    const userAsJoiner = this.getUserRsvpsAsJoiner(userId).find((p) => {
      return p.player === userId;
    });

    if (!userAsJoiner) return rsvpStatus || null;
    return userAsJoiner.rsvpStatus || rsvpStatus;
  }

  getCurrentUserEventStatus(userId: string) {
    if (!userId) {
      return EventRsvpStatus.NOT_PLAYING;
    }
    const userAsJoiner = this.getUserRsvpsAsJoiner(userId).find((p) => {
      return p.player === userId;
    });

    return userAsJoiner?.status;
  }

  getDescription(status: EventJoinerStatus | EventRsvpStatus) {
    if (this.isGameInPast) {
      return `This event has already occurred.`;
    }

    if (this.cancelled) {
      return 'This event has been cancelled!';
    }

    switch (status) {
      case EventJoinerStatus.Approved:
        return 'You have joined this event!';
      case EventJoinerStatus.Waitlisted:
        return 'You are on the waitlist';
      case EventJoinerStatus.PendingCreator:
        return 'You have requested to join and are awaiting approval';
      case EventJoinerStatus.Denied:
        return 'Your request to join has been denied';
      case EventJoinerStatus.PendingJoiner:
        return 'You have been invited to join this event!';
      case EventJoinerStatus.PendingApprovers:
        return 'You have requested to join and are awaiting approval';
    }

    return '';
  }

  get isBottomNavCollapsed() {
    return this.eventInfoState.isBottomCollapsed;
  }
}
