@if (participantsExist()) {
  <div class="participants-container">
    @for (participant of visibleParticipants(); track participant.id; let i = $index) {
      <mat-card [style.zIndex]="5 - i">
        <ark-user-avatar
          [src]="getPhotoUrl(participant)"
          size="large"
          matBadge="+{{ participant.metadata.guestCount }}"
          [matBadgeHidden]="!participant.metadata.guestCount"
        ></ark-user-avatar>
        <div class="card-name">{{ participant.displayNameFallback.split(' ')[0] }}</div>
      </mat-card>
    }
    @if (additionalParticipantsExist()) {
      <mat-card>
        <div matRipple class="circle blue" (click)="openParticipantStatusDialog(vm())">+{{ additionalParticipantsCount() }}</div>
        <button class="link-btn card-name" (click)="openParticipantStatusDialog(vm())">
          Others
        </button>
      </mat-card>
    }
  </div>
  <div class="action-container">
    <button mat-stroked-button color="secondary" (click)="openParticipantStatusDialog(vm())">
      View all participants
    </button>
    <p class="mat-caption"> {{ totalCount() }} {{ totalCount() === 1 ? 'has responded' : 'have responded' }}
      @if (!vm().event.isBasic && vm().playerCountObj.totalNeeded > 0) {
        <span class="needed-text">({{ remainingParticipantsCount() }} more spots available)</span>
      }
    </p>
  </div>
}
