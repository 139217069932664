import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { EventItemGuest } from '@index/interfaces';
import { GthEventItemForm, GthGenderModel } from '@sentinels/models';
import { SrvApiService } from '@sentinels/services/api.service';

@Component({
  selector: 'gth-rsvp-with-guest-dialog',
  templateUrl: './rsvp-with-guest-dialog.component.html',
  styleUrls: ['./rsvp-with-guest-dialog.component.scss'],
})
export class GthRsvpWithGuestDialogComponent implements OnInit {
  genders: GthGenderModel[] = [];

  private eventItemForm = new GthEventItemForm();
  @Input() buttonText = 'ASK TO JOIN GAME';

  constructor(
    private api: SrvApiService,
    private dialogRef: MatDialogRef<GthRsvpWithGuestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      buttonText?: string,
      guests: EventItemGuest[],
    },
  ) { }

  async ngOnInit() {
    this.genders = await this.api.genders.listAsync();

    if (this.data?.buttonText) this.buttonText = this.data?.buttonText;
    const guests = this.data?.guests;
  }
}
