import { CurrencyPipe, formatCurrency } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { TabGroupComponent } from '../event-main-info/components/tab-group/tab-group.component';

@Component({
  selector: 'gth-rsvp-bar',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CurrencyPipe,
  ],
  templateUrl: './rsvp-bar.component.html',
  styleUrl: './rsvp-bar.component.scss',
})
export class RsvpBarComponent {
  cost = input.required<number>();
  hasRSVPed = input<boolean>(false);

  tabGroup = inject(TabGroupComponent);

  costText = computed(() => {
    return this.cost() === 0 ? 'Free!' : formatCurrency(this.cost(), 'en', '$');
  });

  onRsvpClick() {
    this.tabGroup.selectedTab.set('rsvp');
  };
}
