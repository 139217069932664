// event.selectors.ts

import { createFeatureSelector, createSelector } from '@ngrx/store';
import moment from 'moment-timezone';

import { ArkSearchBarFilter } from '../../../../../../ark/src/public-api';
import { selectEventsByCreatorId, selectEventsByLatLngForAuthUser } from '../events/selectors';
import { EventState } from '../events/state';
import { JoinerState } from './state';

export const selectJoinerState = createFeatureSelector<EventState>('event');

export const selectJoinersByEventId = (eventId: string) =>
  createSelector(
    selectJoinerState,
    (state: JoinerState) => {
      return state.joiners[eventId];
    },
  );

export const selectAllJoiners =
  createSelector(
    selectJoinerState,
    (state: JoinerState) => state.joiners,
  );

export const selectJoinersMappedwithEvents = (
  start = 0,
  end?: number,
  filter?: ArkSearchBarFilter,
) =>
  createSelector(
    selectAllJoiners,
    selectEventsByLatLngForAuthUser(filter),
    (joiners, events) => {
      return events
        .filter((event) => {
          // If the event ends before right now, filter it out
          const now = Date.now();
          return event.dateEnd.getTime() > now;
        })
        .filter((event) => {
          // If the event is private, filter it out
          return !event.isPrivate;
        })
        .map((event) => ({ event, joiners: joiners[event.id] || [] })).sort((a, b) => {
          return moment(a.event.dateStart).diff(moment(b.event.dateStart));
        }).slice(start, end);
    },
  );

export const selectJoinersMappedWithEventsByCreatorId = (creatorId: string) =>
  createSelector(
    selectAllJoiners,
    selectEventsByCreatorId(creatorId),
    (joiners, events) => {
      return events
        .filter((event) => {
          const now = Date.now();
          return event.dateEnd.getTime() > now;
        })
        .map((event) => ({ event, joiners: joiners[event.id] || [] }))
        .sort((a, b) => moment(a.event.dateStart).diff(moment(b.event.dateStart)))
        .slice(0, 5);
    },
  );

export const selectEventsByUids = (eventId: string[]) =>
  createSelector(
    selectJoinerState,
    (state: JoinerState) => eventId.map((id) => state.joiners[id]).filter((event) => !!event),
  );

