import { CurrencyPipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, OnInit, output, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ButtonMenuOptions } from '@gth-legacy/directives/button-menu/button-menu.component';
import { EventJoiner, EventSlot, EventTicketLevel } from '@index/interfaces';

import { SlotFormGroup } from '../../../rsvp-steps/rsvp-steps.component';
import { SlotParticipant } from '../../rsvp-slot-management.component';

@Component({
  selector: 'gth-rsvp-slot',
  standalone: true,
  imports: [
    JsonPipe,
    CurrencyPipe,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
  ],
  templateUrl: './rsvp-slot.component.html',
  styleUrl: './rsvp-slot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsvpSlotComponent implements OnInit {
  eventSlots = input<EventSlot[]>([]);
  slotFormGroup = input<FormGroup<SlotFormGroup>>(null);
  eventRequiresGuestInformation = input(false);
  ticketLevels = input<EventTicketLevel[]>([]);
  participants = input<EventJoiner[]>([]);
  isBasic = input(false);
  hasGroups = input(false);

  showCard = computed(() =>
    !this.isBasic() || this.slotParticipantCtrl.value === SlotParticipant.GUEST,
  );

  readonly removeSlot = output();

  selectedEventSlot = signal<EventSlot>(null);
  selectedGroup = signal<string>(null);

  showGuestFieldsSignal = signal(false);
  showGuestFields = this.showGuestFieldsSignal.asReadonly();

  showSlotSelection = computed(() =>
    (!this.isBasic() && !this.hasGroups()) || (this.hasGroups() && this.selectedGroup()),
  );

  remainingAvailableSlots = computed(() => {
    const selectedEventSlot = this.selectedEventSlot();
    const selectedGroup = this.selectedGroup();
    if (!selectedEventSlot) return 0;

    const parentFormArray =
      this.slotFormGroup().parent as FormArray<FormGroup<SlotFormGroup>>;

    const count = parentFormArray.value
      .reduce((acc, slot) => {
        const sameSlot = slot.eventSlot.number === selectedEventSlot.number;
        const sameGroup = this.hasGroups() ? slot.groupName === selectedGroup : true;
        if (sameSlot && sameGroup) return acc + 1;
        return acc;
      }, 0);

    const otherParticipantsCount = this.participants().filter((p) => {
      const sameGroup = this.hasGroups() ? p.groupName === selectedGroup : true;
      return p.slotNumber === selectedEventSlot.number && sameGroup;
    }).length;

    const capacity = this.hasGroups() ?
      Math.floor(selectedEventSlot.capacity / selectedEventSlot.groupsList.length) :
      selectedEventSlot.capacity;

    const spotsLeft = capacity - count - otherParticipantsCount;

    return spotsLeft < 0 ? 0 : spotsLeft;
  });

  eventSlotsWithCost = computed<(EventSlot & {cost: number})[]>(() => {
    const ticketLevels = this.ticketLevels();
    const eventSlots = this.eventSlots();
    return eventSlots.map((s) => {
      const slotTicketLevel = ticketLevels.find((t) => s.number === t.slotNumber);
      return { ...s, cost: slotTicketLevel?.cost || 0 };
    });
  });

  groups = computed(() => {
    const eventSlots = this.eventSlots();
    const allGroups = eventSlots.flatMap((es) => es.groupsList || []);
    /** Create a set to remove duplicates */
    return Array.from(new Set(allGroups));
  });

  buttonMenuOptions: ButtonMenuOptions[] = Object.values(SlotParticipant)
    .map((value) => ({
      value: value,
      viewValue: value.replaceAll('For ', ''),
      disabled: false,
    }));

  ngOnInit(): void {
    if (this.eventSlotCtrl.value) {
      const slotWithCost = this.eventSlotsWithCost().find(
        (s) => s.number === this.eventSlotCtrl.value.number,
      );
      if (!slotWithCost) return;
      this.eventSlotCtrl.setValue(slotWithCost);
    }

    if (this.eventSlotCtrl.value) {
      this.selectedEventSlot.set(this.eventSlotCtrl.value);
    }

    if (this.slotParticipantCtrl.value) {
      return this.onRsvpChange(this.slotParticipantCtrl.value);
    }

    if (this.isBasic()) {
      this.onRsvpChange(SlotParticipant.SELF);
    }
  }

  get eventSlotCtrl() {
    return this.slotFormGroup().controls?.eventSlot;
  }

  get slotParticipantCtrl() {
    return this.slotFormGroup().controls?.slotParticipant;
  }
  get slotParticipant() {
    return this.slotParticipantCtrl.value;
  }

  get guestNameCtrl() {
    return this.slotFormGroup().controls?.guestName;
  }
  get guestEmailCtrl() {
    return this.slotFormGroup().controls?.guestEmail;
  }

  get groupNameCtrl() {
    return this.slotFormGroup().controls?.groupName;
  }
  get groupName() {
    return this.groupNameCtrl?.value;
  }

get displayWaitlistWarning() {
  if (this.selectedEventSlot() === null) return false;

  return this.hasGroups() ?
  this.eventSlotCtrl.hasWarnings &&
  this.eventSlotCtrl.warnings['groups']?.contains(this.selectedGroup()) === 0 :
  this.eventSlotCtrl.hasWarnings;
}

  onDeleteBtnClick() {
    this.removeSlot.emit();
  }

  onRsvpChange(slotParticipant: SlotParticipant) {
    switch (slotParticipant) {
      case SlotParticipant.SELF:
        this.showGuestFieldsSignal.set(false);
        this.slotFormGroup().removeControl('guestName');
        this.slotFormGroup().removeControl('guestEmail');
        break;
      case SlotParticipant.GUEST:
        if (this.eventRequiresGuestInformation()) {
          this.showGuestFieldsSignal.set(true);
          this.slotFormGroup().addControl(
            'guestName',
            new FormControl<string>(null, Validators.required),
          );
          this.slotFormGroup().addControl(
            'guestEmail',
            new FormControl<string>(null, [Validators.required, Validators.email]),
          );
        } else this.showGuestFieldsSignal.set(false);
        break;
    }
    this.slotParticipantCtrl.setValue(slotParticipant);
  }

  onSelectEventSlot(event: MatSelectChange) {
    this.selectedEventSlot.set(event.value);
  }

  onGroupSelect(event: MatSelectChange) {
    this.selectedGroup.set(event.value);
    /** below triggers event slot validator to run based on group change */
    this.eventSlotCtrl.setValue(this.selectedEventSlot());
  }
}
