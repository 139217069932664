import { CurrencyPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EventJoiner } from '@index/interfaces';
import { StripeItemType } from '@sentinels/enums';
import { StripeItem } from '@sentinels/interfaces/stripe';
import { GthEventItemModel, GthStripeSubscriptionModel, GthTeamModel, GthUserModel } from '@sentinels/models';

import { StripePaymentElementComponent, StripePaymentElementReadyContract } from '../../components/stripe-payment-element/stripe-payment-element.component';

export interface PaymentDialogContract {
  type: StripeItemType,
  userId: string
  team?: GthTeamModel,
  subscription?: GthStripeSubscriptionModel;
  event?: GthEventItemModel,
  email?: string, // used for guest users
  hasPlatformFee?: boolean, // used for joining event with cost
  platform: 'gth' | 'meh',
  lifetime?: boolean,
  joiners?: EventJoiner[],
}

@Component({
  selector: 'gth-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    StripePaymentElementComponent,
    NgIf,
    CurrencyPipe,
    MatProgressSpinnerModule,
    NgTemplateOutlet,
  ],
})
export class PaymentDialogComponent implements OnInit {
  title = 'Payment Fees';
  description?: string;
  loading = true;
  occurrence = '/month';
  type?: StripeItemType;
  userId?: string;
  items: StripeItem[] = [];
  email?: string;
  hasPlatformFee = true;
  platform: 'gth' | 'meh' = 'gth';
  joiners?: EventJoiner[];

  team?: GthTeamModel;
  subscription?: GthStripeSubscriptionModel;

  event?: GthEventItemModel;

  subtotal?: number;
  serviceFees?: number;
  taxes?: number;
  total?: number;
  constructor(@Inject(MAT_DIALOG_DATA) private data: PaymentDialogContract) {}

  async ngOnInit() {
    if (this.data?.type) this.type = this.data.type;
    if (this.data?.userId) this.userId = this.data.userId;
    if (this.data?.email) this.email = this.data.email;
    if (typeof this.data?.hasPlatformFee === 'boolean') {
      this.hasPlatformFee = this.data.hasPlatformFee;
    }
    if (this.data?.platform) this.platform = this.data.platform;
    if (this.data?.joiners) this.joiners = this.data.joiners;

    this.occurrence = this.data.lifetime? ' For Life' : this.occurrence;
    switch (this.type) {
      case StripeItemType.JOIN_EVENT:
        this.title = 'Event Fees';
        if (this.data?.event) {
          this.event = this.data?.event;
          this.description = `Payment to join ${this.event.title}`;
          /** Convert event to item to send to backend to create payment intent */
          if (!this.event.priceId) throw Error('Event must be called with a Stripe price');
          if (
            this.event?.cost ||
            this.event.selectedTicketLevel?.cost &&
            this.joiners.length === 1
          ) {
            this.items = [
              {
                id: this.event.selectedTicketLevel ?
                  this.event.selectedTicketLevel.priceId :
                  this.event.priceId,
                name: this.event.selectedTicketLevel ?
                  `${this.event.title}: ${this.event.selectedTicketLevel.name}` :
                  this.event.title,
                quantity: this.joiners?.length ?? 1,
                cost: this.event.selectedTicketLevel ?
                  this.event.selectedTicketLevel.cost :
                  this.event.cost,
                type: StripeItemType.JOIN_EVENT,
                platform: this.platform,
              },
            ];
          } else {
            if (
              this.event.ticketLevels.length &&
              this.event.ticketLevels.some((t) => !!t?.cost && !t?.priceId)
            ) {
              throw new Error('Event ticket levels must have a Stripe price');
            }
            this.items = this.joiners.map((j) => {
              const joinerTicketLevel = this.event.ticketLevels
                .find((t) => t.slotNumber === j.slotNumber);
              const eventSlot = this.event.eventSlotGroup[0].slots
                .find((s) => s.number === j.slotNumber);
              let itemName = `${this.event.title}: ${eventSlot.name}`;
              if (j?.groupName) itemName = `${itemName} for ${j.groupName}`;
              return {
                id: joinerTicketLevel.priceId,
                name: itemName,
                quantity: 1,
                cost: joinerTicketLevel.cost,
                type: StripeItemType.JOIN_EVENT,
                platform: this.platform,
              };
            });
          }
        }
        break;
      case StripeItemType.CHANGE_TEAM_SUBSCRIPTION:
        this.title = 'Subscription Fees';
        if (this.data?.team && this.data.subscription) {
          this.team = this.data.team;
          this.subscription = this.data.subscription;
          this.description = `Payment to change team subscription`;
          /** Convert team subscription change to item */
          this.items = [
            {
              id: this.team.id,
              name: this.subscription.label,
              quantity: 1,
              cost: this.subscription.cost,
              type: StripeItemType.CHANGE_TEAM_SUBSCRIPTION,
              subscriptionPriceId: this.subscription.priceId,
              platform: this.platform,
            },
          ];
        }
        break;
      case StripeItemType.CHANGE_USER_SUBSCRIPTION:
        this.title = 'Subscription Fees';
        if (this.data?.userId && this.data.subscription) {
          this.userId = this.data.userId;
          this.subscription = this.data.subscription;
          this.description = `Payment to upgrade user subscription`;
          /** Convert team subscription change to item */
          this.items = [
            {
              id: this.data.userId,
              name: this.subscription.label,
              quantity: 1,
              cost: this.subscription.cost,
              type: StripeItemType.CHANGE_USER_SUBSCRIPTION,
              subscriptionPriceId: this.subscription.priceId,
              platform: this.platform,
            },
          ];
        }
        break;
      case StripeItemType.LIFETIME_USER_SUBSCRIPTION:
        this.title = 'Lifetime Subscription Fee';
        if (this.data?.userId && this.data?.subscription) {
          this.userId = this.data?.userId;
          this.subscription = this.data.subscription;
          this.description = `Payment for lifetime subscription`;
          /** Convert lifetime subscription to item */
          this.items = [
            {
              id: this.userId,
              name: this.subscription.label,
              quantity: 1,
              cost: this.subscription.cost,
              type: StripeItemType.LIFETIME_USER_SUBSCRIPTION,
              subscriptionPriceId: this.subscription.priceId,
              platform: this.platform,
            },
          ];
        }
        break;
    }

    this.loading = false;
  }

  onStripePaymentElementReady(payment: StripePaymentElementReadyContract) {
    this.total = payment.total ?? (this.event.selectedTicketLevel ?
      this.event.selectedTicketLevel.cost :
      this.event.cost);
    this.subtotal = payment.subtotal ?? 0;
    this.taxes = payment.taxes ?? 0;
    this.serviceFees = payment.serviceFees ?? 0;
  }

  protected readonly StripeItemType = StripeItemType;
}
