<mat-card>
  <mat-card-header>
    <mat-card-title>Contact Information</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Full Name</mat-label>
      <input matInput [(ngModel)]="guestName" placeholder="Ex. John Doe" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="guestEmail" placeholder="Email" #email="ngModel" required email />
    </mat-form-field>
  </mat-card-content>
</mat-card>