import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';

export interface ButtonMenuOptions {
  viewValue: string;
  value: any;
  disabled: boolean,
  disabledText?: string
}

@Component({
  selector: 'gth-button-menu',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.scss'],
})
export class ButtonMenuComponent {
  @ViewChild('t')
  menuTrigger!: MatMenuTrigger;
  @ViewChild('button')
  button!: MatButton;

  @Input()
  options: ButtonMenuOptions[] = [];
  @Input()
  @Output()
  itemClick = new EventEmitter();
  @Input()
  disabled = false;
  changed = false;

  menuOpen: boolean = false;
  isSmallScreen: boolean = false;

  constructor() {}

  get isMenuOpen() {
    return !!this.menuTrigger?.menuOpen;
  }

  onClick(item: ButtonMenuOptions) {
    this.itemClick.emit(item.value);
    this.changed = true;
  }
}
