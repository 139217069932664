@for (
  slotFormGroup of slotsFormArray().controls;
  track slotFormGroup.value;
  let i = $index;
) {
  <gth-rsvp-slot
    [eventSlots]="eventSlots()"
    [slotFormGroup]="slotFormGroup"
    [eventRequiresGuestInformation]="eventRequiresGuestInformation()"
    [ticketLevels]="ticketLevels()"
    [participants]="participants()"
    [isBasic]="isBasic()"
    [hasGroups]="hasGroups()"
    (removeSlot)="onRemoveSlot(i)"
  />
}

<div
  class="action-btn"
  matTooltip="Clear errors first to add more slots"
  [matTooltipDisabled]="slotsFormArray().valid"
>
  <button
    mat-raised-button
    [disabled]="slotsFormArray().invalid"
    (click)="addSlotToForm()"
  >
    <mat-icon fontIcon="add" />
    <span>Add Attendee</span>
  </button>
</div>