import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { StripeItemType } from '@sentinels/enums';
import { StripeItem } from '@sentinels/interfaces/stripe';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import {
  loadStripe, PaymentIntent, Stripe, StripeElements,
  StripeElementsOptionsClientSecret, StripeError,
} from '@stripe/stripe-js';

import { StripeService } from '../../services/stripe.service';
import { GTH_ENVIRONMENT, GthEnvironment } from '../../tokens/environment-tokens';

export interface StripePaymentElementReadyContract {
  total: number,
  taxes: number,
  serviceFees: number,
  subtotal: number,
}

@Component({
  selector: 'gth-stripe-payment-element',
  templateUrl: './stripe-payment-element.component.html',
  styleUrls: ['./stripe-payment-element.component.scss'],
  standalone: true,
  imports: [MatButtonModule, FormsModule, NgIf, MatProgressSpinnerModule, NgClass],
})
export class StripePaymentElementComponent implements OnInit {
  @ViewChild('paymentElement')
  paymentElement: ElementRef<HTMLDivElement>;

  @Input()
  items?: StripeItem[];

  @Input()
  stripeAccount?: string;

  @Input()
  email?: string;

  @Input()
  redirectPath?: string;

  @Input()
  type = StripeItemType.JOIN_EVENT;

  @Input()
  hasPlatformFee = true;

  @Input()
  platform: 'gth' | 'meh' = 'gth';

  @Output()
  ready = new EventEmitter<StripePaymentElementReadyContract>();

  private stripe?: Stripe;
  private stripeElements?: StripeElements;
  paymentError?: StripeError;
  loading = true;
  constructor(
    @Inject(GTH_ENVIRONMENT) private config: GthEnvironment,
    private stripeService: StripeService,
    private safeWindow: SrvSafeWindowService,
    private snackbar: MatSnackBar,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.stripe = await loadStripe(environment.stripe);
    if (!this.items) throw new Error('No items provided for stripe payment element');

    let clientSecret;
    let total;
    let subtotal;
    let taxes;
    let serviceFees;
    let subscription;

    switch (this.type) {
      case StripeItemType.JOIN_EVENT:
        /** Create checkout session */
        const sessionUrl = await this.stripeService.createCheckoutSession({
          items: this.items,
          stripeAccount: this.stripeAccount,
          email: this.email,
          hasPlatformFee: this.hasPlatformFee,
          platform: this.platform,
          environment: environment.envName,
        });
        if (sessionUrl) this.safeWindow.open(sessionUrl, '_self');

        /** Create payment intent */
        // const paymentIntent = await this.stripeService.createPaymentIntent({
        //   items: this.items,
        //   stripeAccount: this.stripeAccount,
        //   email: this.email,
        //   hasPlatformFee: this.hasPlatformFee,
        //   platform: this.platform,
        //   environment: environment.envName,
        // }) as PaymentIntent & {
        //   metadata: {
        //     total: number,
        //     taxes: number,
        //     serviceFees: number,
        //     subtotal: number,
        //   },
        // };
        // if (!paymentIntent) {
        //   this.snackbar.open(
        //     'Something went wrong creating payment intent',
        //     'OK',
        //     { duration: 0 },
        //   );
        //   break;
        // }
        // clientSecret = paymentIntent.client_secret;
        // total = paymentIntent.amount / 100;
        // subtotal = paymentIntent.metadata.subtotal / 100;
        // taxes = paymentIntent.metadata.taxes / 100;
        // serviceFees = paymentIntent.metadata.serviceFees / 100;
        break;
      case StripeItemType.CHANGE_TEAM_SUBSCRIPTION:
      case StripeItemType.CHANGE_USER_SUBSCRIPTION:
        subscription = await this.stripeService
          .createSubscription({ items: this.items, environment: environment.envName });
        // const subscription = await this.stripeService
        //   .createSubscription({ items: this.items }) as any;
        // clientSecret = subscription.latest_invoice.payment_intent.client_secret;
        // total = subscription.latest_invoice.payment_intent.amount / 100;
        // subtotal = subscription.latest_invoice.payment_intent.metadata.subtotal / 100;
        // taxes = subscription.latest_invoice.payment_intent.metadata.taxes / 100;
        // serviceFees = subscription.latest_invoice.payment_intent.metadata.serviceFees / 100;
        break;
      case StripeItemType.LIFETIME_USER_SUBSCRIPTION:
        /** Create payment intent */
        const lifetimePaymentIntent = await this.stripeService.createPaymentIntent({
          items: this.items,
          platform: this.platform,
          environment: environment.envName,
        });
        if (!lifetimePaymentIntent) {
          this.snackbar.open(
            'Something went wrong creating payment intent',
            'OK',
            { duration: 0 },
          );
          break;
        }
        clientSecret = lifetimePaymentIntent.client_secret;
        total = lifetimePaymentIntent.amount / 100;
        break;
    }

    if (subscription?.session?.url) {
      this.safeWindow.open(subscription.session.url, '_self');
    }
    if (!clientSecret) return;

    /** Set up stripe.js */
    this.stripe = await loadStripe(
      environment.stripe,
      this.stripeAccount ? { stripeAccount: this.stripeAccount } : undefined,
    );

    /** Create payment element */
    const options: StripeElementsOptionsClientSecret = {
      clientSecret,
      appearance: undefined,
    };
    this.stripeElements = this.stripe.elements(options);

    /** Create and mount the payment element */
    const paymentElement = this.stripeElements.create('payment');
    paymentElement.mount(this.paymentElement?.nativeElement ?? '#payment-element');

    if (!this.redirectPath) this.redirectPath = this.router.url;
    this.loading = false;
    this.ready.emit({
      total,
      taxes,
      serviceFees,
      subtotal,
    });
  }

  async onPayBtnClick() {
    this.paymentError = undefined;

    const schemeDomain = environment.envName === 'local' ?
      `http://localhost:420${this.platform === 'gth' ? '0' : '1'}` : this.config.root;
    const { error } = await this.stripe.confirmPayment({
      /** Elements instance that was used to create the payment intent */
      elements: this.stripeElements,
      confirmParams: {
        return_url: `${schemeDomain}${this.redirectPath}`,
      },
    });

    if (error) {
      /**
       * This point will only be reached if there is an immediate error when
       * confirming the payment. Show error to your customer (for example, payment
       * details incomplete.)
       */
      this.paymentError = error;
      console.error('Something went wrong confirming payment', error);
    } else {
      /**
       * Your customer will be redirected to your `return_url`. For some payment
       * methods like iDEAL, your customer will be redirected to an intermediate
       * site first to authorize the payment, then redirected to the `return_url`.
       */
    }
  }
}
