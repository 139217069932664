import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';

import { GthGoogleMapMarkerComponent } from './components/map-marker/google-map-marker.component';
import { GthGoogleMapComponent } from './google-map.component';

@NgModule({
  imports: [
    CommonModule,
    GoogleMapsModule,
  ],
  exports: [GthGoogleMapComponent, GthGoogleMapMarkerComponent],
  declarations: [GthGoogleMapComponent, GthGoogleMapMarkerComponent],
})
export class GthGoogleMapModule { }
